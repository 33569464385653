import CodeBlock from '@tiptap/extension-code-block';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Link from '@tiptap/extension-link';
import { Editor } from '@tiptap/react';
import { KeyTakeAways } from '../extensions/key-takeaways';
import { Article } from '../extensions/article';
import { Example } from '../extensions/example';
import { PracticalTips } from '../extensions/practical-tips';
import { Embed } from '../extensions/embed';
import { DocumentBlock } from '../extensions/document-block';
import { DiscussionPrompt } from '../extensions/discussion-prompt';
import { Warning } from '../extensions/warning';
import { ImageBlock } from '../extensions/image-block';
import { ImageUpload } from '../extensions/image-upload';

export const isTableGripSelected = (node: HTMLElement) => {
  let container = node;

  while (container && !['TD', 'TH'].includes(container.tagName)) {
    container = container.parentElement!;
  }

  const gripColumn = container?.querySelector && container?.querySelector('a.grip-column.selected');
  const gripRow = container?.querySelector && container?.querySelector('a.grip-row.selected');

  if (gripColumn || gripRow) {
    return true;
  }

  return false;
};

export const isCustomNodeSelected = (editor: Editor, node: HTMLElement) => {
  const customNodes = [
    HorizontalRule.name,
    ImageBlock.name,
    ImageUpload.name,
    CodeBlock.name,
    Link.name,
    KeyTakeAways.name,
    Article.name,
    Example.name,
    PracticalTips.name,
    Embed.name,
    DocumentBlock(false).name,
    DiscussionPrompt.name,
    Warning.name,

    // AiWriter.name,
    // AiImage.name,
    // Figcaption.name,
    // TableOfContentsNode.name,
  ];

  return customNodes.some((type) => editor.isActive(type)) || isTableGripSelected(node);
};

export default isCustomNodeSelected;
