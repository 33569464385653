import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { ArticleComponent } from './article-component';
import { BlockEditorTypesEnum } from '../../../block-editor/block-editor';

export default Node.create({
  name: BlockEditorTypesEnum.ARTICLE,
  group: 'block',

  content: '',

  addAttributes() {
    return {
      title: {
        default: null,
      },
      description: {
        default: null,
      },
      source: {
        default: null,
      },
      link: {
        default: null,
      },
      faviconUrl: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-article]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['article-node-view', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ArticleComponent);
  },
});
