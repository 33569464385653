import { LearningActivityStep } from '@stellar-lms-frontend/lms-api';
import { EditHeader } from '../learning-activity/components/edit-header';
import { useCallback, useContext, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  ContentWrapper,
  Form,
  HeaderImage,
  Heading4,
  ImageSettings,
  ScrollContainer,
} from '@stellar-lms-frontend/ui-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  SurveyQuestionTypeNamesEnum,
  SurveyQuestionTypes,
  fieldSchemas,
  transformSurveyQuestionsToSubmit,
} from './survey-functions';
import { SurveyEditQuestions } from './survey-edit-questions';
import { LearningActivityContext, STEP_URI_CREATE_CONST, SURVEY } from '../learning-activity';
import { useLearningActivityState } from '../learning-activity/hooks/use-learning-activity-state';
import { useAISuggestionsButton } from '../learning-activity/hooks/use-ai-suggestions-button';
import {
  CreateSurveyStepMutation,
  DesignerSurveyStepQuery,
  DesignerSurveyStepQueryVariables,
  MutationCreateSurveyStepArgs,
  MutationUpdateSurveyStepArgs,
  SurveyStepInput,
  UpdateSurveyStepMutation,
  useCurrentCompany,
} from '@stellar-lms-frontend/lms-graphql';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDefaultHeaderImage } from '../../hooks';
import { useParams } from 'react-router-dom';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';

type FormData = {
  title: string;
  questions: SurveyQuestionTypes[];
  headerImage?: ImageSettings;
};

type Actions = {
  survey?: {
    create?: (variables: MutationCreateSurveyStepArgs) => Promise<CreateSurveyStepMutation>;
    update?: (variables: MutationUpdateSurveyStepArgs) => Promise<UpdateSurveyStepMutation>;
    fetch?: (variables: DesignerSurveyStepQueryVariables) => Promise<DesignerSurveyStepQuery>;
    getDefaultHeaderImage?: (courseId: string) => Promise<ImageSettings | undefined>;
  };
};

export type SurveyStepEditProps = {
  courseId: string;
  learningActivityId: string;
  step?: LearningActivityStep;
  actions: Actions;
};

export const SurveyStepEdit: React.FC<SurveyStepEditProps> = ({
  courseId,
  learningActivityId,
  step,
  actions,
}: SurveyStepEditProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'survey-step-view' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });
  const { stepId } = useParams();

  const schema = yup.object().shape({
    title: yup.string().required(tValidation('required')),
    questions: yup
      .array()
      .of(
        yup.lazy(
          (field: { __typename: SurveyQuestionTypeNamesEnum }) =>
            fieldSchemas({ required: tValidation('required') })[field.__typename],
        ),
      ),
  });
  const { setSaveFunc } = useContext(LearningActivityContext);

  const useFormRet = useForm<FormData>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      title: step?.title,
      questions: [],
      headerImage: undefined,
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isDirty, isValid },
  } = useFormRet;

  useDefaultHeaderImage(
    stepId,
    () => actions.survey?.getDefaultHeaderImage?.(courseId) ?? Promise.resolve(undefined),
    (headerImage: ImageSettings) => {
      setValue('headerImage', headerImage);
    },
  );
  useLearningActivityState({ isDirty, isValid });

  const isQueryEnabled =
    !!learningActivityId &&
    !!step?.id &&
    step?.id !== STEP_URI_CREATE_CONST &&
    actions?.survey?.fetch !== undefined;
  useQuery(
    [learningActivityId, step?.id, SURVEY],
    () =>
      isQueryEnabled
        ? (actions?.survey?.fetch?.({ learningActivityId, stepId: step?.id }) ?? null)
        : null,
    {
      enabled: isQueryEnabled,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        reset({
          title: step?.title,
          headerImage: step?.headerImage,
          questions:
            data?.learningActivityStep &&
            data.learningActivityStep.__typename === 'SurveyStep' &&
            data?.learningActivityStep.questions
              ? data.learningActivityStep.questions
              : undefined,
        });
      },
    },
  );

  const onSave = useCallback(
    async (data: SurveyStepInput) => {
      if (step?.id && step.id !== STEP_URI_CREATE_CONST) {
        await actions?.survey?.update?.({
          learningActivityId,
          stepId: step?.id,
          input: data,
        });
      } else {
        await actions?.survey?.create?.({
          learningActivityId,
          input: data,
        });
      }
    },
    [actions?.survey, learningActivityId, step?.id],
  );

  useEffect(() => {
    setSaveFunc?.(() =>
      handleSubmit((data) =>
        onSave({
          title: data.title,
          survey: {
            questions: transformSurveyQuestionsToSubmit(data.questions),
          },
          headerImage: data.headerImage?.fileId
            ? { fileId: data.headerImage.fileId, isFullWidth: data.headerImage.isFullWidth }
            : undefined,
        }),
      ),
    );
  }, [handleSubmit, onSave, setSaveFunc]);

  useAISuggestionsButton({
    isLocked: true,
  });

  const {
    query: { data: company },
  } = useCurrentCompany(defaultGraphqlClient);

  if (!company) {
    return null;
  }

  return (
    <ScrollContainer scrollOnDesktop={true}>
      <FormProvider {...useFormRet}>
        <Form className="mb-5">
          <Controller
            control={control}
            name="headerImage"
            render={({ field }) => (
              <HeaderImage
                {...field}
                companyId={company.id}
                isEditing={true}
              />
            )}
          />
          <ContentWrapper className="font-lexend mx-auto space-y-4 pb-6 pt-4">
            <Controller
              control={control}
              name="title"
              render={({ field }) => (
                <EditHeader
                  {...field}
                  onChangeTitle={(e) => field.onChange(e.target.value)}
                  placeholder={t('title-placeholder')}
                  error={errors.title?.message}
                />
              )}
            />

            <Heading4 className="text-text-01">{t('edit.title')}</Heading4>
            <p className="text-text-02 text-sm">{t('edit.subtitle')}</p>
            <SurveyEditQuestions name="questions" />
          </ContentWrapper>
        </Form>
      </FormProvider>
    </ScrollContainer>
  );
};

export default SurveyStepEdit;
