import { Node } from '@tiptap/pm/model';
import { NodeSelection } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';
import { useCallback } from 'react';

const useContentItemActions = (
  editor: Editor,
  currentNode: Node | null,
  currentNodePos: number,
) => {
  const resetTextFormatting = useCallback(() => {
    const chain = editor.chain();

    chain.setNodeSelection(currentNodePos).unsetAllMarks();

    if (currentNode?.type.name !== 'paragraph') {
      chain.setParagraph();
    }

    chain.run();
  }, [editor, currentNodePos, currentNode?.type.name]);

  const duplicateNode = useCallback(() => {
    editor.commands.setNodeSelection(currentNodePos);

    const { $anchor } = editor.state.selection;
    const selectedNode = $anchor.node(1) || (editor.state.selection as NodeSelection).node;

    editor
      .chain()
      .setMeta('hideDragHandle', true)
      .insertContentAt(currentNodePos + (currentNode?.nodeSize || 0), selectedNode.toJSON())
      .run();
  }, [editor, currentNodePos, currentNode?.nodeSize]);

  const copyNodeToClipboard = useCallback(() => {
    editor.chain().setMeta('hideDragHandle', true).setNodeSelection(currentNodePos).run();

    document.execCommand('copy');
  }, [editor, currentNodePos]);

  const deleteNode = useCallback(() => {
    editor
      .chain()
      .setMeta('hideDragHandle', true)
      .setNodeSelection(currentNodePos)
      .deleteSelection()
      .run();
  }, [editor, currentNodePos]);

  const moveUp = useCallback(() => {
    if (currentNodePos >= 1 && currentNode) {
      const newPos = currentNodePos - 1;

      editor
        .chain()
        .focus()
        .insertContentAt(newPos, currentNode?.content)
        .deleteCurrentNode()
        .run();
    }
  }, [currentNode, currentNodePos, editor]);
  const moveDown = useCallback(() => {
    if (currentNode) {
      const newPos = currentNodePos + 1;

      editor
        .chain()
        .focus()
        .selectParentNode()
        .insertContentAt(newPos, currentNode?.content)
        .deleteCurrentNode()
        .run();
    }
  }, [currentNode, currentNodePos, editor]);
  // const moveNode = (type, dir = 'UP') => {
  //   const isDown = dir === 'DOWN';
  //   return (state, dispatch) => {
  //     if (!state.selection.empty) {
  //       return false;
  //     }

  //     const { $from } = state.selection;

  //     const currentResolved = findParentNodeOfType(type)(state.selection);

  //     if (!currentResolved) {
  //       return false;
  //     }

  //     const { node: currentNode } = currentResolved;
  //     const parentDepth = currentResolved.depth - 1;
  //     const parent = $from.node(parentDepth);
  //     const parentPos = $from.start(parentDepth);

  //     if (currentNode.type !== type) {
  //       return false;
  //     }

  //     const arr = mapChildren(parent, (node) => node);
  //     let index = arr.indexOf(currentNode);

  //     let swapWith = isDown ? index + 1 : index - 1;

  //     // If swap is out of bound
  //     if (swapWith >= arr.length || swapWith < 0) {
  //       return false;
  //     }

  //     const swapWithNodeSize = arr[swapWith].nodeSize;
  //     [arr[index], arr[swapWith]] = [arr[swapWith], arr[index]];

  //     let tr = state.tr;
  //     let replaceStart = parentPos;
  //     let replaceEnd = $from.end(parentDepth);

  //     const slice = new Slice(Fragment.fromArray(arr), 0, 0); // the zeros  lol -- are not depth they are something that represents the opening closing
  //     // .toString on slice gives you an idea. for this case we want them balanced
  //     tr = tr.step(new ReplaceStep(replaceStart, replaceEnd, slice, false));

  //     tr = tr.setSelection(
  //       Selection.near(
  //         tr.doc.resolve(isDown ? $from.pos + swapWithNodeSize : $from.pos - swapWithNodeSize),
  //       ),
  //     );
  //     if (dispatch) {
  //       dispatch(tr.scrollIntoView());
  //     }
  //     return true;
  //   };
  // };

  const handleAdd = useCallback(() => {
    if (currentNodePos !== -1) {
      const currentNodeSize = currentNode?.nodeSize || 0;
      const insertPos = currentNodePos + currentNodeSize;
      const currentNodeIsEmptyParagraph =
        currentNode?.type.name === 'paragraph' && currentNode?.content?.size === 0;
      const focusPos = currentNodeIsEmptyParagraph ? currentNodePos + 2 : insertPos + 2;

      editor
        .chain()
        .command(({ dispatch, tr, state }) => {
          if (dispatch) {
            if (currentNodeIsEmptyParagraph) {
              tr.insertText('/', currentNodePos, currentNodePos + 1);
            } else {
              tr.insert(
                insertPos,
                state.schema.nodes['paragraph'].create(null, [state.schema.text('/')]),
              );
            }

            return dispatch(tr);
          }

          return true;
        })
        .focus(focusPos)
        .run();
    }
  }, [currentNode, currentNodePos, editor]);

  return {
    resetTextFormatting,
    duplicateNode,
    copyNodeToClipboard,
    deleteNode,
    handleAdd,
    moveUp,
    moveDown,
  };
};

export default useContentItemActions;
