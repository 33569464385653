import { useState } from 'react';
import { FileInput, UploadFile } from '../../../../form';
import { fileUploader } from '@stellar-lms-frontend/common-utils';
import { getFileUrl, getUploadUrl, uploadFile } from '@stellar-lms-frontend/lms-api';

export const DocumentUploader = ({
  onUpload,
  companyId,
}: {
  companyId: string;
  onUpload: (url: string, fileId: string, fileName: string, mimeType: string) => void;
}) => {
  const uploader = fileUploader(getUploadUrl, uploadFile, getFileUrl);

  const [file, setFile] = useState<UploadFile>({ status: 'init' });
  const handleFileAdded = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile({ status: 'loading', progress: 0 });
      const file = e.target.files[0];

      try {
        const uploadUrl = await uploader.uploadByFile(file, companyId, (progress) => {
          setFile({ status: 'loading', progress: progress });
        });
        setFile({ status: 'complete', file: file, fileId: uploadUrl.fileId });
        onUpload(uploadUrl.url, uploadUrl.fileId, file.name, file.type);
      } catch (error) {
        console.log('Error uploading file: ', error);
        setFile({ status: 'error' });
      }
    }
  };

  return (
    <div contentEditable={false}>
      <FileInput
        // className="w-0 h-0 overflow-hidden opacity-0"
        className="w-full"
        accept=".docx,.md,.pdf,.pptx,.txt"
        maxFileSize={{
          size: 512000, // 500MB
          errorMessage: 'File size exceeds 500MB',
        }}
        onChange={handleFileAdded}
        i18n={{ invalidFormat: '' }}
        file={file}
      />
    </div>
  );
};

export default DocumentUploader;
