import { IconItem } from '../../../list';
import { FloatingOptionsOption } from '../floating-options';
import React from 'react';

export const OptionItem = React.forwardRef<
  HTMLDivElement,
  {
    item: FloatingOptionsOption;
    hasDivider: boolean;
    isActive?: boolean;
  }
>(({ item, hasDivider, isActive }, ref) => {
  return (
    <div ref={ref}>
      {
        {
          option: (
            <IconItem
              title={item.label}
              onClick={item.onClick}
              left={item.left}
              subtitleList={
                item.subtitle ? [<span className="text-text-02">{item.subtitle}</span>] : undefined
              }
              className={`
              hover:bg-surface-02 text-text-01 w-full px-6 py-3 text-start rounded-md
                ${item.className ?? ''}
                ${hasDivider ? 'border-b-1 border-border-02 last:border-none' : ''}
                ${isActive ? 'bg-surface-02' : ''}
              `}
            />
          ),

          divider: <div className={`${item.className ?? ''} border-b-1 border-border-02`}></div>,

          title: <p className="text-text-02 type-small-medium px-6 py-2">{item.label}</p>,

          // There is actual use for the fragment. Label is defined as a react node, while a react element is expected as return value...
          // eslint-disable-next-line react/jsx-no-useless-fragment
          custom: <>{item.label}</>,
        }[item.type ?? 'option']
      }
    </div>
  );
});
