import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';

import LearningActivityStepOverview, {
  LearningActivityStepOverviewActions,
  LearningActivityStepOverviewI18N,
} from './learning-activity-step-overview';
import LearningActivityStepView, {
  LAStepViewActions,
  I18N as StepI18N,
} from './learning-activity-step-view';
import { VoidFunc, navigation, useScreenType } from '@stellar-lms-frontend/common-utils';
import LearnerUIShell, { LearnerUIShellProps } from '../learner-ui-shell/learner-ui-shell';
import {
  Breadcrumbs,
  Edit3Icon,
  EmptyState,
  JourneyIllustration,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '@stellar-lms-frontend/ui-components';
import { LearningActivityContext } from './context/learning-activity-context';
import { autocompleteLearningActivity } from '../module-list/functions';
import { useCourseData } from './hooks/use-course-data';
import { Course, GetLightCourseInfoQuery } from '@stellar-lms-frontend/lms-graphql';

const NAVIGATION_KEY = 'learning-activity';
const courseOverviewPath = '..';
const learningActivityOverviewPath = '.';
export type I18N = StepI18N & {
  laStepOverview: LearningActivityStepOverviewI18N;
  empty?: {
    title: string;
    description: string;
  };
};

export type LearningActivityOverviewActions = {
  course: {
    getCourseOutline: (input: { courseId: string }) => Promise<Course>;
  };
  edit?: {
    onLeave: (callback: VoidFunc, saveFunc?: () => Promise<void>) => void;
    onNavigateModule: (courseId: string, moduleId: string) => void;
  };
};

export type Actions = LearningActivityOverviewActions &
  LearningActivityStepOverviewActions &
  LAStepViewActions;

export type LearningActivityOverviewProps = {
  companyId: string;
  documentsEnabled?: boolean;
  i18n: I18N;
  isEditing?: boolean;
  isCreating?: boolean;
  setIsEditing?: Dispatch<SetStateAction<boolean>>;
  discussionAllowed: boolean;
  currentCourse?: GetLightCourseInfoQuery['course'];
  actions: Actions;
};

export const LearningActivityOverview: React.FC<LearningActivityOverviewProps> = ({
  companyId = '',
  documentsEnabled,
  i18n,
  discussionAllowed = true,
  isEditing = false,
  isCreating = false,
  setIsEditing,
  currentCourse,
  actions,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'learning-activity-overview' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const navigate = useNavigate();
  const { courseId, stepId } = useParams();
  const { isTailwindLg } = useScreenType();
  const { saveFunc, isDirty, isValid } = useContext(LearningActivityContext);
  const [isSaveEnabled, setIsSaveEnabled] = useState(true);

  const resolvedPathToCourseOverview = useResolvedPath(courseOverviewPath);
  const resolvedPathToLearningActivityOverview = useResolvedPath(learningActivityOverviewPath);

  const { modules, moduleIndex, learningActivityIndexInModule, moduleId } = useCourseData(
    actions.course.getCourseOutline,
  );

  useEffect(() => {
    const navigateToCourseOverview = () => {
      navigation.clear();
      navigate(`${courseOverviewPath}/?moduleId=${moduleId}`, {
        replace: true,
      });
    };

    const navigateToLearningActivityOverview = () => {
      navigation.clear();
      navigate(learningActivityOverviewPath);
    };

    const navigateBack = () => {
      if (!isTailwindLg && stepId !== undefined) {
        navigateToLearningActivityOverview();
      } else {
        navigateToCourseOverview();
      }
    };

    navigation.setLeftPrimary(NAVIGATION_KEY, {
      label: t('back-to-course'),
      action: navigateBack,
    });

    navigation.setTitle(NAVIGATION_KEY, t('navigation.title'));
  }, [
    moduleId,
    stepId,
    t,
    navigate,
    isTailwindLg,
    resolvedPathToCourseOverview.pathname,
    resolvedPathToLearningActivityOverview.pathname,
  ]);

  useEffect(() => () => navigation.clear(), []);

  const emptyStepView = useMemo(() => {
    return i18n.empty ? (
      <EmptyState
        className="w-full"
        icon={<JourneyIllustration />}
        title={i18n.empty.title}
        description={i18n.empty.description}
        verticalCenter={true}
      />
    ) : (
      <div className="w-full" />
    );
  }, [i18n]);

  const learnerUIShellConfig = useMemo<LearnerUIShellProps>(
    () =>
      isEditing
        ? {
            headerTheme: 'edit',
            title: (
              <Breadcrumbs
                breadcrumbs={[
                  {
                    label: moduleIndex !== undefined ? (modules?.[moduleIndex]?.title ?? '') : '',
                    onClick: () =>
                      courseId && moduleId && actions?.edit?.onNavigateModule(courseId, moduleId),
                  },
                  {
                    label: t('edit-step'),
                    onClick: () => null,
                  },
                ]}
              />
            ),
            backButton: {
              onClick: () =>
                isDirty
                  ? actions?.edit?.onLeave(() => setIsEditing?.(false), saveFunc)
                  : setIsEditing?.(false),
              label: tGeneral('back'),
            },
            initialHeaderRightElements: [
              {
                id: 'save-preview',
                element: (
                  <SecondaryButton
                    label={tGeneral('save-preview')}
                    buttonStyle="small"
                    onClick={() => {
                      setIsSaveEnabled(false);
                      saveFunc?.().finally(() => {
                        setIsSaveEnabled(true);
                        isValid && setIsEditing?.(false);
                      });
                    }}
                    enabled={isSaveEnabled}
                  />
                ),
              },
              {
                id: 'save',
                element: (
                  <PrimaryButton
                    label={tGeneral('save')}
                    buttonStyle="small"
                    enterListener={false}
                    onClick={() => {
                      setIsSaveEnabled(false);
                      saveFunc?.().finally(() => setIsSaveEnabled(true));
                    }}
                    enabled={isSaveEnabled}
                  />
                ),
              },
            ],
          }
        : {
            headerTheme: 'view',
            initialHeaderRightElements: setIsEditing
              ? [
                  {
                    id: 'edit',
                    element: (
                      <TertiaryButton
                        leftIcon={<Edit3Icon className="text-text-01 h-6 w-6" />}
                        label={tGeneral('edit')}
                        onClick={() => setIsEditing?.(true)}
                        enabled={!isEditing}
                        buttonStyle="small"
                      />
                    ),
                  },
                ]
              : [],
          },
    [
      actions?.edit,
      courseId,
      isDirty,
      isValid,
      isEditing,
      isSaveEnabled,
      moduleId,
      moduleIndex,
      modules,
      saveFunc,
      setIsEditing,
      t,
      tGeneral,
    ],
  );

  if (
    learningActivityIndexInModule === undefined ||
    moduleIndex === undefined ||
    modules === undefined
  ) {
    return null;
  }

  return (
    <LearnerUIShell {...learnerUIShellConfig}>
      <div className="flex h-full w-full flex-row">
        {(isTailwindLg || !stepId) && (
          <div className="item w-full justify-center lg:flex lg:w-[488px] lg:shrink-0 lg:shadow">
            <LearningActivityStepOverview
              discussionAllowed={discussionAllowed}
              i18n={i18n.laStepOverview}
              setIsEditing={setIsEditing}
              currentCourse={currentCourse}
              isEditing={isEditing}
              isCreating={isCreating}
              actions={actions}
              autoCompletePrerequisites={(query) =>
                autocompleteLearningActivity(
                  query,
                  learningActivityIndexInModule,
                  moduleIndex,
                  modules,
                )
              }
            />
          </div>
        )}
        {stepId ? (
          <div className="grow overflow-hidden">
            <LearningActivityStepView
              companyId={companyId}
              i18n={i18n}
              currentCourse={currentCourse}
              discussionAllowed={discussionAllowed}
              isEditing={isEditing}
              actions={actions}
              documentsEnabled={documentsEnabled}
            />
          </div>
        ) : (
          emptyStepView
        )}
      </div>
    </LearnerUIShell>
  );
};
