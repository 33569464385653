import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export const setupSentry = (environment: 'staging' | 'production' | 'local', dsn: string) => {
  const tracingSampleRate = environment === 'production' ? 0.1 : 1.0;

  Sentry.init({
    dsn: dsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    beforeSend: (event, hint) => {
      if (environment === 'local') {
        console.error(hint.originalException || hint.syntheticException, event.extra);
        return null; // this drops the event and nothing will be sent to sentry
      }
      return event;
    },
    environment: environment,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: tracingSampleRate,
  });
};

export async function unimplementedActionError() {
  Sentry.captureException('Unimplemented action called while it should not have been called');
  return Promise.reject();
}
