import { mergeAttributes, Node } from '@tiptap/core';

import { createPasteRules, getAttributes, getEmbedUrl } from './embed-utils';
import { BlockEditorTypesEnum } from '../../../block-editor/block-editor';

export interface EmbedOptions {
  src: string;
  HTMLAttributes: Record<string, any>;
}

export const Embed = Node.create<EmbedOptions>({
  name: BlockEditorTypesEnum.EMBED,
  draggable: true,
  group: 'block',
  content: 'paragraph',

  addOptions() {
    return {
      src: '',
      HTMLAttributes: {
        class: 'mb-2',
        contenteditable: false,
      },
    };
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      embedType: {
        default: null,
      },
      remoteId: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-embed] iframe',
      },
    ];
  },

  addPasteRules() {
    return createPasteRules(this.type);
  },

  renderHTML({ HTMLAttributes }) {
    const type = HTMLAttributes['embedType'];
    const embedUrl = getEmbedUrl(type, HTMLAttributes['remoteId']);
    const iframeHTMLAttributes = getAttributes(type);

    HTMLAttributes['src'] = embedUrl;

    return [
      'div',
      { 'data-embed': '', class: 'mb-4' },
      [
        'iframe',
        mergeAttributes(this.options.HTMLAttributes, iframeHTMLAttributes, HTMLAttributes),
      ],
      ['div', { class: 'border-1 border-border-02 rounded-sm p-1' }, 0],
    ];
  },
});
