import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { DocumentComponent } from './view/document-block-component';
import { BlockEditorTypesEnum } from '../../../block-editor/block-editor';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    documentBlock: {
      setDocumentBlock: (attributes: {
        src: string;
        fileId: string;
        fileName: string;
        mimeType: string;
      }) => ReturnType;
    };
  }
}

export const DocumentBlock = (documentsEnabled: boolean) =>
  Node.create({
    name: BlockEditorTypesEnum.DOCUMENT_BLOCK,
    group: 'block',

    content: '',

    addAttributes() {
      return {
        fileName: {
          default: null,
        },
        src: {
          default: null,
        },
        mimeType: {
          default: null,
        },
        fileId: {
          default: null,
        },
        documentsEnabled: {
          default: documentsEnabled,
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: `div[data-${this.name}]`,
        },
      ];
    },
    addCommands() {
      return {
        setDocumentBlock:
          (attrs) =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: {
                src: attrs.src,
                fileId: attrs.fileId,
                fileName: attrs.fileName,
                mimeType: attrs.mimeType,
              },
            });
          },
      };
    },

    renderHTML({ HTMLAttributes }) {
      return ['document-node-view', mergeAttributes(HTMLAttributes), 0];
    },

    addNodeView() {
      return ReactNodeViewRenderer(DocumentComponent);
    },
  });

export default DocumentBlock;
