import { NodeType } from '@tiptap/pm/model';
import { nodePasteRule, PasteRule } from '@tiptap/react';

export type SupportedServices =
  | 'youtube'
  | 'genially'
  | 'synthesia'
  | 'nepazing'
  | 'outgrow'
  | 'hubspot'
  | 'vimeo'
  | 'imgur'
  | 'gfycat'
  | 'miro';

type Config = {
  regex: RegExp;
  embedUrl: string;
  html: string;
  htmlAttributes: Record<string, any>;
  id?: (groups: string[]) => string;
};

const regexConfig: Record<SupportedServices, Config> = {
  youtube: {
    regex:
      /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/g,
    embedUrl: 'https://www.youtube.com/embed/<%= remote_id %>',
    html: '<iframe style="width:100%;" height="320" frameborder="0" allowfullscreen></iframe>',
    htmlAttributes: {
      allow: 'autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
      allowfullscreen: true,
      style: 'width: 100%; aspect-ratio: 1920/1080',
    },
    id: ([src, id, params]) => {
      if (!params && id) {
        return id;
      }

      const paramsMap: Record<string, string> = {
        start: 'start',
        end: 'end',
        t: 'start',
        // eslint-disable-next-line camelcase
        time_continue: 'start',
        list: 'list',
      };

      const newParams = params
        .slice(1)
        .split('&')
        .map((param) => {
          const [name, value] = param.split('=');

          if (!id && name === 'v') {
            id = value;

            return null;
          }

          if (!paramsMap[name]) {
            return null;
          }

          if (value === 'LL' || value.startsWith('RDMM') || value.startsWith('FL')) {
            return null;
          }

          return `${paramsMap[name]}=${value}`;
        })
        .filter((param) => !!param);

      return id + '?' + newParams.join('&');
    },
  },
  genially: {
    regex: /^https:\/\/view.(?:genial.ly|genially.com)\/([a-z0-9]*)(\/.*)?\/?$/g,
    embedUrl: 'https://view.genially.com/<%= remote_id %>',
    html: "<iframe frameborder='0' style='width: 100%; aspect-ratio: 1920/1080' allowfullscreen ></iframe>",
    htmlAttributes: {
      allowfullscreen: true,
      style: 'width: 100%; aspect-ratio: 1920/1080',
      frameborder: 0,
    },
    id: (groups: string[]) => groups[0],
  },
  synthesia: {
    regex: /^https:\/\/share\.synthesia\.io\/(?:embeds\/videos\/)?([a-z0-9-]+)$/g,
    embedUrl: 'https://share.synthesia.io/embeds/videos/<%= remote_id %>',
    html: "<iframe frameborder='0' style='width: 100%; aspect-ratio: 1920/1080' loading='lazy' allow='encrypted-media; fullscreen;' ></iframe>",
    htmlAttributes: {
      allowfullscreen: true,
      style: 'width: 100%; aspect-ratio: 1920/1080',
      frameborder: 0,
      loading: 'lazy',
    },
    id: (groups: string[]) => groups[0],
  },
  nepazing: {
    regex: /https:\/\/nepazing.com\/wordsearch\/embed\/([a-zA-Z0-9]*)/g,
    embedUrl: 'https://nepazing.com/wordsearch/embed/<%= remote_id %>',
    html: "<iframe frameborder='0' style='height: 1000px; width: 100%;' allowfullscreen ></iframe>",
    htmlAttributes: {
      allowfullscreen: true,
      style: 'height: 1000px; width: 100%;',
      frameborder: 0,
    },
    id: (groups: string[]) => groups[0],
  },
  outgrow: {
    regex: /^https:\/\/(.*).outgrow.us\/([a-z0-9]*)\??(\S*)?$/g,
    embedUrl: '<%= remote_id %>',
    html: "<iframe frameborder='0' style='height: 1000px; width: 100%;' allowfullscreen ></iframe>",
    htmlAttributes: {
      allowfullscreen: true,
      style: 'height: 1000px; width: 100%;',
      frameborder: 0,
      scrolling: 'no',
    },
    id: (groups: string[]) => {
      const url: string = 'https://' + groups[0] + '.outgrow.us/' + groups[1];
      return url;
    },
  },
  hubspot: {
    regex: /^(https:\/\/.*.hubspot.com\/.*)$/g,
    embedUrl: '<%= remote_id %>',
    html: "<iframe frameborder='0' style='height: 700px; width: 100%;' allowfullscreen ></iframe>",
    htmlAttributes: {
      allowfullscreen: true,
      style: 'height: 700px; width: 100%;',
      frameborder: 0,
      scrolling: 'no',
    },
    id: (groups: string[]) => groups[0],
  },
  vimeo: {
    regex: /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^/]\d+)(?:#t=[\d]+)?s?)/g,
    embedUrl: 'https://player.vimeo.com/video/<%= remote_id %>?title=0&byline=0',
    html: '<iframe style="width:100%;" height="320" frameborder="0"></iframe>',
    htmlAttributes: {
      scrolling: 'no',
      width: '100%',
      height: '320',
      frameborder: 0,
    },
  },
  imgur: {
    regex: /https?:\/\/(?:i\.)?imgur\.com.*\/([a-zA-Z0-9]+)(?:\.gifv)?/g,
    embedUrl: 'https://imgur.com/<%= remote_id %>/embed',
    html: '<iframe allowfullscreen="true" scrolling="no" id="imgur-embed-iframe-pub-<%= remote_id %>" class="imgur-embed-iframe-pub" style="height: 500px; width: 100%; border: 1px solid #000"></iframe>',
    htmlAttributes: {
      scrolling: 'no',
      width: '100%',
      height: '500px',
      frameborder: 0,
    },
  },
  gfycat: {
    regex: /https?:\/\/gfycat\.com(?:\/detail)?\/([a-zA-Z]+)/g,
    embedUrl: 'https://gfycat.com/ifr/<%= remote_id %>',
    html: "<iframe frameborder='0' scrolling='no' style=\"width:100%;\" height='436' allowfullscreen ></iframe>",
    htmlAttributes: {
      scrolling: 'no',
      width: '100%',
      height: '436',
      frameborder: 0,
    },
  },
  miro: {
    regex: /https:\/\/miro.com\/\S+(\S{12})\/(\S+)?/g,
    embedUrl: 'https://miro.com/app/live-embed/<%= remote_id %>',
    html: '<iframe width="700" height="500" style="margin: 0 auto;" allowFullScreen frameBorder="0" scrolling="no"></iframe>',
    htmlAttributes: {
      width: '700',
      height: '500',
      frameborder: 0,
      scrolling: 'no',
    },
  },
};

export function createPasteRules(type: NodeType): PasteRule[] {
  return Object.entries(regexConfig).map(([key, value]) =>
    nodePasteRule({
      find: value.regex,
      type: type,
      getAttributes: (match) => {
        return {
          src: match.input,
          remoteId: calculateRemoteId([...match.values()], key as SupportedServices),
          embedType: key,
        };
      },
    }),
  );
}

export function getAttributes(embedType: SupportedServices) {
  return regexConfig[embedType].htmlAttributes;
}

export function getEmbedUrl(embedType: SupportedServices, remoteId: string) {
  return regexConfig[embedType].embedUrl.replace('<%= remote_id %>', remoteId);
}
export function calculateRemoteId(groups: string[], type: SupportedServices) {
  let remoteId = groups[0];
  if (regexConfig[type].id) {
    remoteId = regexConfig[type].id(groups);
  } else if (groups.length > 0) {
    remoteId = groups[1];
  }

  return remoteId;
}

export function matchAndCalculateRemoteId(source: string, type: SupportedServices) {
  const groups = regexConfig[type].regex.exec(source);

  return groups ? calculateRemoteId(groups, type) : source;
}
