import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/pro-light-svg-icons';
import { EmptyStateCard } from '../../../../card/empty-state-card/empty-state-card';
import { renderFileIcon } from '../../../../icons/file-icons.util';

export const DocumentComponent = (props: NodeViewProps) => {
  const fileName = props.node.attrs['fileName'];
  const src = props.node.attrs['src'];
  const mimeType = props.node.attrs['mimeType'];
  const documentsEnabled = props.node.attrs['documentsEnabled'];

  return (
    <NodeViewWrapper className="document-node-view">
      {documentsEnabled ? (
        <div className="py-3">
          <a
            href={src}
            target="_blank"
            rel="noreferrer"
            draggable={false}
          >
            <EmptyStateCard>
              <div className="flex flex-row items-center justify-center space-x-4">
                {renderFileIcon(mimeType)}
                <span className="text-text-01 grow">{fileName}</span>
                <FontAwesomeIcon
                  icon={faFileArrowDown}
                  className="h-6 w-6"
                />
              </div>
            </EmptyStateCard>
          </a>
        </div>
      ) : null}
    </NodeViewWrapper>
  );
};
