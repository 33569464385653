import { mergeAttributes, Node } from '@tiptap/core';
import { BlockEditorTypesEnum } from '../../../block-editor/block-editor';

export default Node.create({
  name: BlockEditorTypesEnum.PRACTICAL_TIPS,
  group: 'block',

  content: 'heading orderedList',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'my-2 space-y-6 rounded-xl border border-border-02 p-2 px-8',
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  // addKeyboardShortcuts() {
  //   return {
  //     Enter: ({ editor }) => {
  //       const { state } = editor;
  //       const { selection } = state;
  //       const { $from, empty } = selection;

  //       if (!empty || $from.parent.type !== this.type) {
  //         return false;
  //       }

  //       const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;
  //       const endsWithDoubleNewline = $from.parent.textContent.endsWith('\n\n');

  //       if (!isAtEnd || !endsWithDoubleNewline) {
  //         return false;
  //       }

  //       return editor
  //         .chain()
  //         .command(({ tr }) => {
  //           tr.delete($from.pos - 2, $from.pos);

  //           return true;
  //         })
  //         .exitCode()
  //         .run();
  //     },
  //   };
  // },

  addCommands() {
    return {
      insertPracticalTips:
        () =>
        ({ tr, dispatch, commands }) => {
          if (dispatch) {
            commands.insertContent({
              type: this.name,
              content: [
                { type: 'heading', level: 3, content: [{ type: 'text', text: 'Practical tips' }] },
                {
                  type: 'orderedList',
                  content: [{ type: 'listItem', content: [{ type: 'paragraph', content: [] }] }],
                },
              ],
            });
          }

          return true;
        },
    };
  },
});

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    practicalTips: {
      /**
       * Comments will be added to the autocomplete.
       */
      insertPracticalTips: () => ReturnType;
    };
  }
}
