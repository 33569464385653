import { mergeAttributes, Node } from '@tiptap/core';
import { BlockEditorTypesEnum } from '../../../block-editor/block-editor';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    example: {
      /**
       * Comments will be added to the autocomplete.
       */
      insertExample: () => ReturnType;
    };
  }
}
export default Node.create({
  name: BlockEditorTypesEnum.EXAMPLE,
  group: 'block',

  content: 'heading block+',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'bg-surface-03 font-lexend my-4 flex items-start gap-8 rounded-xl px-8 py-10',
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      ['i', { class: 'icon icon--example text-3xl mt-2', contentEditable: false }],
      ['div', 0],
    ];
  },

  addCommands() {
    return {
      insertExample:
        () =>
        ({ tr, dispatch, commands }) => {
          if (dispatch) {
            commands.insertContent({
              type: this.name,
              content: [
                { type: 'heading', level: 3, content: [{ type: 'text', text: 'Example' }] },
                {
                  type: 'paragraph',
                  content: [],
                },
              ],
            });
          }

          return true;
        },
    };
  },
});
