import { useCallback, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Toolbar } from '../../Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/pro-light-svg-icons';

export type ColorPickerProps = {
  color?: string;
  onChange?: (color: string) => void;
  onClear?: () => void;
};

export const ColorPicker = ({ color, onChange, onClear }: ColorPickerProps) => {
  const [colorInputValue, setColorInputValue] = useState(color || '');

  const handleColorUpdate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setColorInputValue(event.target.value);
  }, []);

  const handleColorChange = useCallback(() => {
    const isCorrectColor = /^#([0-9A-F]{3}){1,2}$/i.test(colorInputValue);

    if (!isCorrectColor) {
      if (onChange) {
        onChange('');
      }

      return;
    }

    if (onChange) {
      onChange(colorInputValue);
    }
  }, [colorInputValue, onChange]);

  return (
    <div className="flex flex-col gap-2">
      <HexColorPicker
        className="w-full"
        color={color || ''}
        onChange={onChange}
      />
      <input
        type="text"
        className="w-full p-2 text-black bg-white border rounded border-neutral-200 focus:outline-1 focus:ring-0 focus:outline-neutral-300"
        placeholder="#000000"
        value={colorInputValue}
        onChange={handleColorUpdate}
        onBlur={handleColorChange}
      />
      <div className="flex flex-wrap items-center gap-1 max-w-[15rem]">
        <Toolbar.Button
          tooltip="Reset color to default"
          onClick={onClear}
        >
          <FontAwesomeIcon icon={faUndo} />
        </Toolbar.Button>
      </div>
    </div>
  );
};
