import { fileUploader } from '@stellar-lms-frontend/common-utils';
import { useState } from 'react';
import { getFileUrl, getUploadUrl, uploadFile } from '@stellar-lms-frontend/lms-api';
import { FileInput, UploadFile } from '../../../../form';

export const ImageUploader = ({
  onUpload,
  companyId,
}: {
  companyId: string;
  onUpload: (url: string, fileId: string) => void;
}) => {
  const uploader = fileUploader(getUploadUrl, uploadFile, getFileUrl);

  const [file, setFile] = useState<UploadFile>({ status: 'init' });
  const handleFileAdded = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile({ status: 'loading', progress: 0 });
      const file = e.target.files[0];

      try {
        const uploadUrl = await uploader.uploadByFile(file, companyId, (progress) => {
          setFile({ status: 'loading', progress: progress });
        });
        setFile({ status: 'complete', file: file, fileId: uploadUrl.fileId });
        onUpload(uploadUrl.url, uploadUrl.fileId);
      } catch (error) {
        console.log('Error uploading file: ', error);
        setFile({ status: 'error' });
      }
    }
  };

  return (
    <div contentEditable={false}>
      <FileInput
        // className="w-0 h-0 overflow-hidden opacity-0"
        className="w-full"
        accept="image/*"
        maxFileSize={{
          size: 10240, // 10MB
          errorMessage: 'File size exceeds 10MB',
        }}
        onChange={handleFileAdded}
        i18n={{ invalidFormat: '' }}
        file={file}
      />
    </div>
  );
};

// export const ImageUploader = ({
//   onUpload,
//   companyId,
// }: {
//   companyId: string;
//   onUpload: (url: string, fileId: string) => void;
// }) => {
//   const { loading, uploadFile } = useUploader({ companyId, onUpload });
//   const { handleUploadClick, ref } = useFileUpload();
//   const { draggedInside, onDrop, onDragEnter, onDragLeave } = useDropZone({ uploader: uploadFile });

//   const onFileChange = useCallback(
//     (e: ChangeEvent<HTMLInputElement>) => (e.target.files ? uploadFile(e.target.files[0]) : null),
//     [uploadFile],
//   );

//   if (loading) {
//     return (
//       <div className="flex items-center justify-center p-8 rounded-lg min-h-[10rem] bg-opacity-80">
//         <SpinnerLoader color={'grey'} />
//       </div>
//     );
//   }

//   const wrapperClass = cn(
//     'flex flex-col items-center justify-center px-8 py-10 rounded-lg bg-opacity-80',
//     draggedInside && 'bg-neutral-100',
//   );

//   return (
//     <div
//       className={wrapperClass}
//       onDrop={onDrop}
//       onDragOver={onDragEnter}
//       onDragLeave={onDragLeave}
//       contentEditable={false}
//     >
//       <FontAwesomeIcon
//         icon={faImage}
//         className="text-2xl"
//       />
//       <div className="flex flex-col items-center justify-center gap-2">
//         <div className="text-sm font-medium text-center text-neutral-400 dark:text-neutral-500">
//           {draggedInside ? 'Drop image here' : 'Drag and drop or'}
//         </div>
//         <div>
//           <Button
//             disabled={draggedInside}
//             onClick={handleUploadClick}
//             variant="primary"
//             buttonSize="small"
//           >
//             <FontAwesomeIcon icon={faUpload} />
//             Upload an image
//           </Button>
//         </div>
//       </div>
//       <input
//         className="w-0 h-0 overflow-hidden opacity-0"
//         ref={ref}
//         type="file"
//         accept=".jpg,.jpeg,.png,.webp,.gif"
//         onChange={onFileChange}
//       />
//     </div>
//   );
// };

export default ImageUploader;
