import { Editor, NodeViewWrapper } from '@tiptap/react';
import { useCallback } from 'react';

import { ImageUploader } from './ImageUploader';
import { Node } from '@tiptap/pm/model';

export const ImageUpload = ({
  getPos,
  editor,
  node,
}: {
  getPos: () => number;
  editor: Editor;
  node: Node;
}) => {
  const companyId = node.attrs['companyId'];
  const onUpload = useCallback(
    (url: string, fileId: string) => {
      if (url) {
        editor
          .chain()
          .setImageBlock({ src: url, fileId: fileId })
          .deleteRange({ from: getPos(), to: getPos() })
          .focus()
          .run();
      }
    },
    [getPos, editor],
  );

  return (
    <NodeViewWrapper>
      <div
        className="p-0 m-0"
        data-drag-handle
      >
        <ImageUploader
          onUpload={onUpload}
          companyId={companyId}
        />
      </div>
    </NodeViewWrapper>
  );
};

export default ImageUpload;
