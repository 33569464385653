import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryButton } from '../../../buttons';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { faMessages } from '@fortawesome/pro-light-svg-icons';

export const DiscussionComponent = (props: NodeViewProps) => {
  return (
    <NodeViewWrapper>
      <div className="font-lexend bg-surface-03 my-4 flex items-start gap-6 rounded-xl p-6">
        <FontAwesomeIcon
          icon={faMessages}
          className="text-text-02 shrink-0 mt-2 text-3xl"
        />
        <div className="space-y-4">
          <NodeViewContent />
          <SecondaryButton
            label={'Show discussion panel'}
            onClick={() => props.editor.commands.openDiscussionPrompt()}
          />
        </div>
      </div>
    </NodeViewWrapper>
  );
};
