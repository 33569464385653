import { v4 as uuidv4 } from 'uuid';

export type Uploader = {
  uploadByFile: (
    file: File,
    companyId: string,
    callback?: (progress: number) => void,
  ) => Promise<UploadResponse>;
  uploadByUrl: (url: string, companyId: string) => Promise<UploadResponse>;
};

export type UploadResponse = {
  url: string;
  fileId: string;
};

export type FileInformation = {
  url?: string; // sometimes this is an upload url, sometimes just a read url. This is not good
  fileId?: string;
};

type GetUploadInformationFunc = (payload: {
  fileName: string;
  mimeType: string;
  companyId: string;
}) => Promise<FileInformation>;
type PerformFileUploadFunc = (
  presignedUrl: string,
  file: File,
  callback?: (progress: number) => void,
) => Promise<void>;
type GetPresignedFileUrlFunc = (payload: {
  fileId: string;
  companyId: string;
}) => Promise<FileInformation>;

export const fileUploader = (
  getPresignedUploadUrlFunc: GetUploadInformationFunc,
  performUploadFunc: PerformFileUploadFunc,
  getPresignedFileUrlFunc: GetPresignedFileUrlFunc,
): Uploader => {
  const isValidImageRequest = (xmlHttpRequest: XMLHttpRequest, maxFileSize: number): boolean => {
    if (xmlHttpRequest.readyState === 4 && xmlHttpRequest.status === 200) {
      const contentType = xmlHttpRequest.getResponseHeader('content-type');
      if (!contentType?.startsWith('image')) {
        return false;
      }
      const contentLength = xmlHttpRequest.getResponseHeader('content-length');
      if (typeof Number(contentLength) === 'number') {
        return Number(contentLength) <= maxFileSize;
      }
    }
    return false;
  };

  const getFileExtension = (contentType: string): string | undefined => {
    switch (contentType) {
      case 'image/gif':
        return '.gif';
      case 'image/jpeg':
        return '.jpeg';
      case 'image/webp':
        return '.webp';
    }
    return undefined;
  };

  return {
    uploadByFile: async function (file, companyId, callback) {
      const uploadInformation = await getPresignedUploadUrlFunc({
        fileName: file.name,
        mimeType: file.type,
        companyId: companyId,
      });

      if (uploadInformation?.url && uploadInformation?.fileId) {
        await performUploadFunc(uploadInformation.url, file, callback);

        const fileInformation = await getPresignedFileUrlFunc({
          fileId: uploadInformation.fileId,
          companyId: companyId,
        });

        if (fileInformation?.url && fileInformation?.fileId) {
          return {
            url: fileInformation.url,
            fileId: fileInformation.fileId,
          };
        }
      }
      // TODO: some kind of server error??
      return Promise.reject();
    },

    uploadByUrl: async function (url, companyId) {
      try {
        const xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open('HEAD', url, false);
        xmlHttpRequest.send();

        if (isValidImageRequest(xmlHttpRequest, 10 * 1024 * 1024)) {
          const contentType = xmlHttpRequest.getResponseHeader('content-type');
          const fileExtension = getFileExtension(contentType ?? '');
          if (contentType && fileExtension) {
            const file = await fetch(url)
              .then((r) => {
                return r.blob();
              })
              .then(
                (blobFile) => new File([blobFile], uuidv4() + fileExtension, { type: contentType }),
              );
            return this.uploadByFile(file, companyId);
          }
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
      return Promise.reject();
    },
  };
};
