import { LearningActivityStep } from '@stellar-lms-frontend/lms-api';
import {
  Article,
  BlockContentStepQuery,
  CreateBlockContentItemMutation,
  CreateBlockContentItemMutationVariables,
  CreateBlockContentStepMutation,
  CreateBlockContentStepMutationVariables,
  EditorJsArticleBlock,
  GetLightCourseInfoQuery,
  MarkTipViewedMutationVariables,
  QueryLearningActivityStepArgs,
  SuggestedSearchTerms,
  UpdateBlockContentItemMutation,
  UpdateBlockContentItemMutationVariables,
  UpdateBlockContentStepMutation,
  UpdateBlockContentStepMutationVariables,
  Video,
} from '@stellar-lms-frontend/lms-graphql';
import { useParams } from 'react-router-dom';
import BlockStepEdit from './block-step-edit';
import BlockStepView, { BlockStepViewProps } from './block-step-view';
import { BLOCK, STEP_URI_CREATE_CONST } from '../learning-activity';
import { VoidFunc, xxHash } from '@stellar-lms-frontend/common-utils';
import { useQuery } from '@tanstack/react-query';
import { LogoLoader } from '@stellar-lms-frontend/ui-components';
import { JSONContent } from '@tiptap/core';
import { transformDatabaseBlocksToTipTap } from './tiptap-helpers';

export type BlockStepProps = {
  companyId: string;
  isEditing?: boolean;
  step?: LearningActivityStep;
  discussionAllowed: boolean;
  actions: BlockActions;
  moduleId?: string;
  currentCourse?: GetLightCourseInfoQuery['course'];
  documentsEnabled?: boolean;
};

export type BlockActions = {
  edit: {
    markTipViewed: (variables: MarkTipViewedMutationVariables) => void;
  };
  block: {
    createStep: (
      variables: CreateBlockContentStepMutationVariables,
    ) => Promise<CreateBlockContentStepMutation>;
    createItem: (
      variables: CreateBlockContentItemMutationVariables,
    ) => Promise<CreateBlockContentItemMutation>;
    updateStep: (
      variables: UpdateBlockContentStepMutationVariables,
    ) => Promise<UpdateBlockContentStepMutation>;
    updateItem: (
      variables: UpdateBlockContentItemMutationVariables,
    ) => Promise<UpdateBlockContentItemMutation>;
    fetch: (variables: QueryLearningActivityStepArgs) => Promise<BlockContentStepQuery>;
    analyseArticleUrl: (url: string, courseId: string) => Promise<EditorJsArticleBlock | undefined>;
    searchStepVideos: (courseId: string, searchQuery: string) => Promise<Video[] | undefined>;
    startVideoSearchTermGeneration: (
      courseId: string,
      moduleId: string,
      learningActivityId: string,
      learningActivityStepId: string,
    ) => Promise<boolean>;
    getSuggestedVideoSearchTerm: (
      courseId: string,
      moduleId: string,
      learningActivityId: string,
      learningActivityStepId: string,
    ) => Promise<SuggestedSearchTerms>;
    searchStepArticles: (courseId: string, searchQuery: string) => Promise<Article[] | undefined>;
    startArticleSearchTermGeneration: (
      courseId: string,
      moduleId: string,
      learningActivityId: string,
      learningActivityStepId: string,
    ) => Promise<boolean>;
    getSuggestedArticleSearchTerm: (
      courseId: string,
      moduleId: string,
      learningActivityId: string,
      learningActivityStepId: string,
    ) => Promise<SuggestedSearchTerms>;
  };
  openSupport: VoidFunc | undefined;
} & BlockStepViewProps['actions'];

export const BlockStep: React.FC<BlockStepProps> = ({
  companyId,
  isEditing,
  step,
  discussionAllowed,
  actions,
  currentCourse,
  moduleId,
  documentsEnabled,
}) => {
  const { learningActivityId, stepId, courseId } = useParams();
  const isQueryEnabled = !!(learningActivityId && stepId && stepId !== STEP_URI_CREATE_CONST);

  const { data: blockQueryData, isLoading } = useQuery(
    [learningActivityId, stepId, BLOCK],
    () => (isQueryEnabled ? actions?.block?.fetch?.({ learningActivityId, stepId }) : null),
    {
      enabled: isQueryEnabled,
      refetchOnWindowFocus: false,
      select: (data): JSONContent => {
        if (
          data?.learningActivityStep &&
          data.learningActivityStep.__typename === 'BlockContentStep' &&
          data.learningActivityStep.blocks
        ) {
          //return transformDatabaseBlocksToBlockNote(data.learningActivityStep.blocks);
          return transformDatabaseBlocksToTipTap(data.learningActivityStep.blocks);
        }

        return [];
      },
    },
  );

  if (!learningActivityId || !courseId || !moduleId) {
    return null;
  }

  if (!blockQueryData || isLoading) {
    return <LogoLoader />;
  }

  if (isEditing) {
    return (
      <BlockStepEdit
        key={`${stepId}-${xxHash(JSON.stringify(step))}`}
        courseId={courseId}
        moduleId={moduleId}
        learningActivityId={learningActivityId}
        step={step}
        actions={actions}
        currentCourse={currentCourse}
        blocks={blockQueryData}
        documentsEnabled={documentsEnabled}
      />
    );
  } else if (step) {
    return (
      <BlockStepView
        companyId={companyId}
        step={step}
        blocks={blockQueryData}
        discussionAllowed={discussionAllowed}
        actions={actions}
        documentsEnabled={documentsEnabled}
      />
    );
  } else {
    return null;
  }
};
