import { mergeAttributes, Node } from '@tiptap/core';
import { BlockEditorTypesEnum } from '../../../block-editor/block-editor';

export default Node.create({
  name: BlockEditorTypesEnum.KEY_TAKEAWAYS,
  group: 'block',

  content: 'heading bulletList',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'my-4 space-y-6 rounded-xl bg-blue-50 p-8',
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const { state } = editor;
        const { selection } = state;
        const { $from, empty } = selection;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;
        const endsWithDoubleNewline = $from.parent.textContent.endsWith('\n\n');

        if (!isAtEnd || !endsWithDoubleNewline) {
          return false;
        }

        return editor
          .chain()
          .command(({ tr }) => {
            tr.delete($from.pos - 2, $from.pos);

            return true;
          })
          .exitCode()
          .run();
      },
    };
  },

  addCommands() {
    return {
      insertKeyTakeaways:
        () =>
        ({ tr, dispatch, commands }) => {
          if (dispatch) {
            commands.insertContent({
              type: this.name,
              content: [
                {
                  type: 'heading',
                  attrs: { level: 3 },
                  content: [{ type: 'text', text: 'Key Takeaways' }],
                },
                {
                  type: 'bulletList',
                  content: [{ type: 'listItem', content: [{ type: 'paragraph', content: [] }] }],
                },
              ],
            });
          }

          return true;
        },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    keyTakeaways: {
      /**
       * Comments will be added to the autocomplete.
       */
      insertKeyTakeaways: () => ReturnType;
    };
  }
}
