import { Dispatch, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownLeftAndArrowUpRightToCenter, faUpload } from '@fortawesome/pro-light-svg-icons';
import { fileUploader } from '@stellar-lms-frontend/common-utils';
import { ImageSettings } from './types';
import React from 'react';
import { ContentContainer } from '../content-container/content-container';
import { ContentWrapper } from '../content-wrapper/content-wrapper';
import { ConditionalWrapper, FloatingOptions } from '../wrappers';
import { Picture } from '../picture';
import { RoundedIconButton } from '../buttons';
import { ImagePlus, MoreVertIcon, TrashIcon } from '../icons';
import { EmptyStateCard } from '../card/empty-state-card/empty-state-card';
import { useFileUpload } from '../form/file-input/use-file-upload';
import { getFileUrl, getUploadUrl, uploadFile } from '@stellar-lms-frontend/lms-api';

type Props = {
  isEditing: boolean;
  value?: ImageSettings;
  companyId: string;
  onChange?: Dispatch<ImageSettings | undefined>;
};

export const HeaderImage = React.forwardRef<HTMLDivElement, Props>(
  ({ isEditing, value, onChange, companyId }: Props, ref) => {
    const { t } = useTranslation('translation', { keyPrefix: 'components.header-image' });
    const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

    const [isImageOptionsOpen, setIsImageOptionsOpen] = useState(false);

    const [image, setImage] = useState(value);
    useEffect(() => {
      setImage(
        value?.fileId
          ? { url: value?.url, isFullWidth: value?.isFullWidth, fileId: value.fileId }
          : undefined,
      );
    }, [value?.url, value?.isFullWidth, value?.fileId]);

    const uploader = fileUploader(getUploadUrl, uploadFile, getFileUrl);

    const handleDataChange = useCallback(
      (newImage: ImageSettings | undefined) => {
        if (onChange) {
          onChange(newImage);
        }
      },
      [onChange],
    );

    const uploadFromComputer = useCallback(
      async (file: File) => {
        const uploadResponse = await uploader.uploadByFile(file, companyId);
        const newImage = {
          isFullWidth: image?.isFullWidth ?? false,
          url: uploadResponse.url,
          fileId: uploadResponse.fileId,
        };
        setImage(newImage);
        handleDataChange(newImage);
        setIsImageOptionsOpen(false);
      },
      [handleDataChange, image?.isFullWidth, uploader, companyId],
    );

    const toggleWidth = useCallback(() => {
      const newImage = image?.fileId
        ? { url: image?.url, fileId: image?.fileId, isFullWidth: !image?.isFullWidth }
        : undefined;
      setImage(newImage);
      handleDataChange(newImage);
      setIsImageOptionsOpen(false);
    }, [handleDataChange, image?.fileId, image?.isFullWidth, image?.url]);

    const { fileInputProps, handleDrop, handleClick, handleDeleteFile } = useFileUpload({
      maxFileSize: 10240, // 10MB
      onChange: (e) => {
        if (e.target?.files && e.target.files.length > 0) {
          uploadFromComputer(e.target.files[0]);
        } else {
          onChange && onChange(undefined);
        }
      },
      accept: 'image/*',
    });

    const deleteImage = useCallback(() => {
      handleDeleteFile();
      setIsImageOptionsOpen(false);
    }, [handleDeleteFile]);

    return (
      <>
        {isEditing && <input {...fileInputProps} />}
        {!image ? (
          isEditing ? (
            <ContentContainer includePadding={false}>
              <ContentWrapper>
                <div
                  className="font-lexend my-6"
                  onDrop={handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                  onClick={handleClick}
                  onKeyDown={handleClick}
                  role="button"
                >
                  <EmptyStateCard>
                    <div className="text-primary-02 flex items-center justify-center">
                      <ImagePlus className="text-primary-02 mr-2" />
                      {t('add-header-image')}
                    </div>
                  </EmptyStateCard>
                </div>
              </ContentWrapper>
            </ContentContainer>
          ) : (
            <div className="pb-6"></div>
          )
        ) : (
          <div className="flex-none">
            <ContentContainer
              includePadding={false}
              allowOverflow={true}
            >
              <ConditionalWrapper
                hasWrapper={!image.isFullWidth}
                wrapper={(children) => <ContentWrapper>{children}</ContentWrapper>}
              >
                <div className={`relative mb-4 w-full ${image.isFullWidth ? 'mb-12' : ' mt-6'}`}>
                  {image.url && (
                    <Picture
                      imageClassName="h-[200px]"
                      source={image.url}
                      alt={t('alt')}
                      rounded={!image.isFullWidth}
                    />
                  )}
                  {isEditing && (
                    <div
                      className={`absolute right-0 top-0 ${
                        image.isFullWidth ? 'mr-10 mt-6' : 'mr-4 mt-5'
                      } `}
                    >
                      <FloatingOptions
                        usePortal={false}
                        widthClassName="max-w-[300px] w-max"
                        wrappedComponent={
                          <div>
                            <RoundedIconButton
                              htmlId="header-image-options-button"
                              icon={<MoreVertIcon />}
                              onClick={() => setIsImageOptionsOpen(!isImageOptionsOpen)}
                            ></RoundedIconButton>
                          </div>
                        }
                        options={[
                          {
                            key: 'fit-with-content-option',
                            label: t('fit-with-content'),
                            onClick: toggleWidth,
                            left: <FontAwesomeIcon icon={faArrowDownLeftAndArrowUpRightToCenter} />,
                          },
                          {
                            key: 'upload-from-computer-option',
                            onClick: handleClick,
                            label: t('replace-from-computer'),
                            left: <FontAwesomeIcon icon={faUpload} />,
                          },
                          {
                            key: 'delete-option',
                            label: tGeneral('delete'),
                            className: 'text-negative-01',
                            onClick: deleteImage,
                            left: <TrashIcon />,
                          },
                        ]}
                        isOpen={isImageOptionsOpen}
                        onClose={() => setIsImageOptionsOpen(false)}
                      />
                    </div>
                  )}
                </div>
              </ConditionalWrapper>
            </ContentContainer>
          </div>
        )}
      </>
    );
  },
);
