import { EditorContent, useEditor } from '@tiptap/react';

import { Editor, JSONContent } from '@tiptap/core';

import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { EditorJsArticleBlock } from '@stellar-lms-frontend/lms-graphql';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { ContentItemMenu } from './menus/ContentItemMenu';
import { LinkMenu } from './menus/LinkMenu';
import { TextMenu } from './menus/TextMenu';
import { useExtensions } from './use-extensions';
import { ColumnsMenu } from './extensions/multi-column/menus';
import { TableColumnMenu, TableRowMenu } from './extensions/table/menus';

export type BlockEditorProps = {
  id: string;
  companyId: string;
  className?: string;
  data?: JSONContent;
  documentsEnabled?: boolean;
} & (
  | {
      isReadOnly?: false;
      placeholder: string;
      articleUrlAnalyserFunc: (url: string) => Promise<EditorJsArticleBlock | undefined>;
      onDiscussClick?: never;
      onSupportClick?: VoidFunc;
    }
  | {
      isReadOnly: true;
      placeholder?: never;
      onDiscussClick: VoidFunc;
      onSupportClick?: never;
    }
);

export const BlockEditor = React.forwardRef<Editor | null, BlockEditorProps>(
  (
    {
      id,
      className,
      data,
      documentsEnabled = false,
      companyId,
      isReadOnly,
      placeholder,
      onDiscussClick,
      onSupportClick,
    },
    ref,
  ) => {
    const menuContainerRef = useRef(null);
    const editorRef = useRef<Editor | null>(null);

    const extensions = useExtensions({
      placeholder,
      companyId,
      documentsEnabled,
      onOpenDiscussionClick: onDiscussClick ?? (() => null),
      onSupportClick: onSupportClick ?? (() => null),
    });

    const editor = useEditor({
      immediatelyRender: true,
      shouldRerenderOnTransaction: false,
      extensions: extensions,
      content: data,
      editable: !isReadOnly,
      editorProps: {
        attributes: {
          autocomplete: 'off',
          autocorrect: 'off',
          autocapitalize: 'off',
        },
      },
    });

    useEffect(() => {
      editorRef.current = editor;
    }, [editor]);

    useImperativeHandle<Editor | null, Editor | null>(ref, () => editorRef.current);

    if (!editor) return null;

    return (
      <div
        className="mx-auto relative w-full max-w-[700px] 2xl:max-w-[800px]"
        ref={menuContainerRef}
      >
        <LinkMenu
          editor={editor}
          appendTo={menuContainerRef}
        />
        <TextMenu editor={editor} />
        <ContentItemMenu editor={editor} />
        <ColumnsMenu
          editor={editor}
          appendTo={menuContainerRef}
        />
        <TableColumnMenu
          editor={editor}
          appendTo={menuContainerRef}
        />
        <TableRowMenu
          editor={editor}
          appendTo={menuContainerRef}
        />
        <EditorContent editor={editor} />
      </div>
    );
  },
);
