import { useEffect } from 'react';
import { getEnvironment, useEnvironment } from '@stellar-lms-frontend/common-utils';
import { getLoggedInUser, USER } from '@stellar-lms-frontend/lms-api';
import { useQuery } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import { allowedHubspotRoles, buildHubspotSettings } from './hubspot.service';
import Cookies from 'universal-cookie';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';

declare global {
  interface Window {
    _hsq: any[];
    HubSpotConversations: {
      widget: {
        load: (options?: { widgetOpen: boolean }) => void;
        open: () => void;
        remove: () => void;
      };
      clear: (options?: { resetWidget: boolean }) => void;
      on: (event: string, callback: () => void) => void;
    };
    hsConversationsSettings: {
      loadImmediately: boolean;
    };
    hsConversationsOnReady: Array<() => void>;
  }
}

function onHubspotChatFlowReady() {
  window.HubSpotConversations.on('widgetClosed', () => {
    window.HubSpotConversations.widget.remove();
  });
  window.HubSpotConversations.widget.remove();
}

/**
 * Wrap this container around any top level page where Hubspot is needed.
 */
export type HubspotContainerType = {
  graphQLClient: GraphQLClient;
  children?: JSX.Element;
};
export const HubspotContainer = ({ children, graphQLClient }: HubspotContainerType) => {
  const { isProduction, isStaging } = useEnvironment();

  const { data: user } = useQuery([USER], () => getLoggedInUser(graphQLClient));
  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient);

  useEffect(() => {
    if (
      user &&
      allowedHubspotRoles.includes(user.role) &&
      company?.id &&
      company.name &&
      (isProduction || isStaging)
    ) {
      const hubspotSettings = buildHubspotSettings(
        user,
        {
          id: company?.id,
          title: company?.name,
        },
        isProduction,
      );
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push(['identify', hubspotSettings]);
      // Event to make sure the identify data is sent, without it the chat can be open with unknown user
      _hsq.push(['trackPageView']);

      if (window.HubSpotConversations) {
        onHubspotChatFlowReady();
      } else {
        window.hsConversationsOnReady = [() => onHubspotChatFlowReady()];
      }
    }
  }, [company?.id, company?.name, isProduction, isStaging, user]);

  return children ?? <Outlet></Outlet>;
};

export const openHubspotChat = () => {
  const { isProduction, isStaging } = getEnvironment();
  if (isProduction || isStaging) {
    window.HubSpotConversations.widget.load();
    window.HubSpotConversations.widget.open();
  }
};

export const cleanUpHubspotChat = () => {
  const cookies = new Cookies();
  cookies.remove('hubspotutk', { path: '/' });
  cookies.remove('__hstc', { path: '/' });
  window?.HubSpotConversations?.clear({ resetWidget: true });
};
