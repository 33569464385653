import axios from 'axios';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import {
  graphql,
  QueryGetFileUrlArgs,
  QueryGetUploadUrlArgs,
} from '@stellar-lms-frontend/lms-graphql';

export const getUploadUrl = (variables: QueryGetUploadUrlArgs) => {
  return defaultGraphqlClient
    .request(
      graphql(`
        query UrlForContentUpload($companyId: ID!, $fileName: String!, $mimeType: String!) {
          getUploadUrl(companyId: $companyId, fileName: $fileName, mimeType: $mimeType) {
            fileId
            url
          }
        }
      `),
      variables,
    )
    .then((res) => ({
      ...res.getUploadUrl,
      fileId: res.getUploadUrl?.fileId ?? undefined,
      url: res.getUploadUrl?.url ?? undefined,
    }));
};

export const getFileUrl = (variables: QueryGetFileUrlArgs) => {
  return defaultGraphqlClient
    .request(
      graphql(`
        query UrlForUploadedContent($companyId: ID!, $fileId: String!) {
          getFileUrl(companyId: $companyId, fileId: $fileId) {
            fileId
            url
          }
        }
      `),
      variables,
    )
    .then((res) => ({
      ...res.getFileUrl,
      fileId: res.getFileUrl?.fileId ?? undefined,
      url: res.getFileUrl?.url ?? undefined,
    }));
};

export const uploadFile = (url: string, file: File, callback?: (progres: number) => void) => {
  return axios
    .put<void>(url, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: (progressEvent) => {
        if (callback) {
          callback(Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 1)));
        }
      },
    })
    .then((res) => res.data);
};
