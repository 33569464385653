import React from 'react';
import { AlertTriangle } from '../../../../icons';
import { TertiaryButton } from '../../../../buttons';

export const DocumentNotSupportedComponent = () => {
  return (
    <div className="py-3">
      <div className="bg-warning-03 font-lexend flex items-start gap-4 rounded-xl px-8 py-6">
        <AlertTriangle className="text-warning-01 h-6 w-6 shrink-0" />
        <div className="flex flex-col gap-3">
          <span className="text-text-04">not supported</span>

          <div>
            <TertiaryButton
              label={'contact support'}
              onClick={() => alert('support clicked')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
