export const getOnsophicApi = () => {
  const currentDomainParts = window.location.hostname.split('.');
  if (currentDomainParts.at(0) === 'localhost') {
    if (process.env['USE_STAGING'] === 'true') {
      return 'https://api.s.stellarlabs.eu/onsophic';
    } else {
      return 'http://localhost:4300/onsophic';
    }
  }

  const baseDomain = currentDomainParts.slice(1).join('.');

  // This assumes a consistent domain scheme for all the different environments.
  return 'https://api.' + baseDomain + '/onsophic';
};

export const getGraphQLApi = () => {
  const currentDomainParts = window.location.hostname.split('.');
  if (currentDomainParts.at(0) === 'localhost') {
    if (process.env['USE_STAGING'] === 'true') {
      return 'https://api.s.stellarlabs.eu/gateway/graphql';
    } else {
      return 'http://localhost:4300/graphql';
    }
  }

  const baseDomain = currentDomainParts.slice(1).join('.');

  // This assumes a consistent domain scheme for all the different environments.
  return 'https://api.' + baseDomain + '/gateway/graphql';
};
