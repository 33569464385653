import {
  getFileUrl,
  getUploadUrl,
  LearningActivityStep,
  uploadFile,
} from '@stellar-lms-frontend/lms-api';
import { EditHeader } from '../learning-activity/components/edit-header';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Editor, JSONContent } from '@tiptap/core';
import {
  BlockEditorTypesEnum,
  BlockEditorV3,
  ContentWrapper,
  HeaderImage,
  ImageSettings,
  ScrollContainer,
} from '@stellar-lms-frontend/ui-components';
import { LearningActivityContext, STEP_URI_CREATE_CONST } from '../learning-activity';
import {
  Article,
  BlockContentStepInput,
  BlockContentType,
  ContentEntitiesGenerationMutationVariables,
  CreateBlockContentStepMutation,
  CreateBlockContentStepMutationVariables,
  EditorJsArticleBlock,
  EditorVersion,
  GetLightCourseInfoQuery,
  MarkTipViewedMutationVariables,
  SuggestedContentEntitiesQuery,
  SuggestedContentEntitiesQueryVariables,
  SuggestedImagesQuery,
  SuggestedImagesQueryVariables,
  SuggestedSearchTerms,
  SuggestionGenerationStatus,
  UpdateBlockContentStepMutation,
  UpdateBlockContentStepMutationVariables,
  useCurrentCompany,
  Video,
} from '@stellar-lms-frontend/lms-graphql';
import * as Sentry from '@sentry/react';
import { useLearningActivityState } from '../learning-activity/hooks/use-learning-activity-state';
import { defaultGraphqlClient, fileUploader, VoidFunc } from '@stellar-lms-frontend/common-utils';
import {
  UseAISuggestionsButtonArgs,
  useAISuggestionsButton,
} from '../learning-activity/hooks/use-ai-suggestions-button';
import { ImageAISidebar } from './components/image-ai-sidebar/image-ai-sidebar';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faLinesLeaning, faVideo } from '@fortawesome/pro-light-svg-icons';
import { VideoAISidebar } from './components/video-ai-sidebar/video-ai-sidebar';
import { ArticleAISidebar } from './components/article-ai-sidebar/article-ai-sidebar';

type Actions = {
  block: {
    fetchSuggestedContentEntities?: (
      variables: SuggestedContentEntitiesQueryVariables,
    ) => Promise<SuggestedContentEntitiesQuery>;
    fetchSuggestedImages?: (
      variables: SuggestedImagesQueryVariables,
    ) => Promise<SuggestedImagesQuery>;
    startContentEntitiesGeneration?: (
      variables: ContentEntitiesGenerationMutationVariables,
    ) => Promise<boolean>;
    updateStep?: (
      variables: UpdateBlockContentStepMutationVariables,
    ) => Promise<UpdateBlockContentStepMutation>;
    createStep?: (
      variables: CreateBlockContentStepMutationVariables,
    ) => Promise<CreateBlockContentStepMutation>;
    analyseArticleUrl: (url: string, courseId: string) => Promise<EditorJsArticleBlock | undefined>;
    searchStepVideos: (courseId: string, searchQuery: string) => Promise<Video[] | undefined>;
    startVideoSearchTermGeneration: (
      courseId: string,
      moduleId: string,
      learningActivityId: string,
      learningActivityStepId: string,
    ) => Promise<boolean>;
    getSuggestedVideoSearchTerm: (
      courseId: string,
      moduleId: string,
      learningActivityId: string,
      learningActivityStepId: string,
    ) => Promise<SuggestedSearchTerms>;
    searchStepArticles: (courseId: string, searchQuery: string) => Promise<Article[] | undefined>;
    startArticleSearchTermGeneration: (
      courseId: string,
      moduleId: string,
      learningActivityId: string,
      learningActivityStepId: string,
    ) => Promise<boolean>;
    getSuggestedArticleSearchTerm: (
      courseId: string,
      moduleId: string,
      learningActivityId: string,
      learningActivityStepId: string,
    ) => Promise<SuggestedSearchTerms>;
  };
  edit: {
    markTipViewed: (variables: MarkTipViewedMutationVariables) => void;
  };
  openSupport: VoidFunc | undefined;
};

export type BlockStepEditProps = {
  courseId: string;
  moduleId: string;
  learningActivityId: string;
  currentCourse?: GetLightCourseInfoQuery['course'];
  step?: LearningActivityStep;
  actions: Actions;
  blocks: JSONContent;
  documentsEnabled?: boolean;
};

export const BlockStepEdit: React.FC<BlockStepEditProps> = ({
  courseId,
  moduleId,
  learningActivityId,
  step,
  currentCourse,
  actions,
  blocks,
  documentsEnabled,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'block-step-view' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });

  const { setSaveFunc, user } = useContext(LearningActivityContext);
  const [title, setTitle] = useState<undefined | string>(step?.title);
  const [headerImage, setHeaderImage] = useState<undefined | ImageSettings>(step?.headerImage);
  const [isDirty, setIsDirty] = useState(false);
  const [isImageAISidebarOpen, setIsImageAISidebarOpen] = useState(false);
  const [isVideoAISidebarOpen, setIsVideoAISidebarOpen] = useState(false);
  const [isArticleAISidebarOpen, setIsArticleAISidebarOpen] = useState(false);
  const editorRef = useRef<Editor | null>(null);

  const AIButtonOptions = useMemo(
    () => [
      {
        label: t('suggestions-button.floating.images'),
        left: (
          <FontAwesomeIcon
            icon={faImage}
            className="text-text-02 text-2xl"
          />
        ),
        onClick: () => {
          setIsImageAISidebarOpen(true);
        },
      },
      {
        label: t('suggestions-button.floating.videos'),
        left: (
          <FontAwesomeIcon
            icon={faVideo}
            className="text-text-02 text-2xl "
          />
        ),
        onClick: () => {
          setIsVideoAISidebarOpen(true);
        },
      },
      {
        label: t('suggestions-button.floating.articles'),
        left: (
          <FontAwesomeIcon
            icon={faLinesLeaning}
            className="text-text-02 text-2xl "
          />
        ),
        onClick: () => {
          setIsArticleAISidebarOpen(true);
        },
      },
    ],
    [t],
  );

  const onSave = useCallback(
    async (data: BlockContentStepInput) => {
      if (step?.id && step?.id !== STEP_URI_CREATE_CONST) {
        await actions?.block?.updateStep?.({ learningActivityId, stepId: step.id, input: data });
      } else {
        await actions?.block?.createStep?.({ learningActivityId, input: data });
      }
    },
    [actions?.block, learningActivityId, step?.id],
  );

  const onClickNewFeatureTip = useCallback(() => {
    user?.id && actions.edit.markTipViewed({ userId: user?.id, tip: 'ai-suggestions-button-tip' });
  }, [actions.edit, user?.id]);

  const aiSuggestionsButtonConfig: UseAISuggestionsButtonArgs = step
    ? {
        options: AIButtonOptions,
        isLocked: false,
        hasAlert: false,
        wasViewed: user?.viewedTips.includes('ai-suggestions-button-tip'),
        onClickNewFeatureTip,
      }
    : {
        isLocked: true,
        wasViewed: user?.viewedTips.includes('ai-suggestions-button-tip'),
        onClickNewFeatureTip,
      };

  useAISuggestionsButton(aiSuggestionsButtonConfig);

  useEffect(() => {
    setSaveFunc?.(() => async () => {
      const editorData = await editorRef.current?.getJSON();
      if (editorData && title) {
        const blocks = editorData.content
          ?.filter(
            (b) =>
              ![BlockEditorV3.DocumentUpload.name, BlockEditorV3.ImageUpload.name].includes(
                b.type ?? '',
              ),
          )
          .map((b) => {
            let blockType: BlockContentType | undefined;
            const data = b;

            switch (b.type as BlockEditorTypesEnum) {
              case BlockEditorTypesEnum.PARAGRAPH:
                blockType = BlockContentType.Paragraph;
                break;

              // FUTURE: delete with editorjs
              case BlockEditorTypesEnum.H1:
                blockType = BlockContentType.Header;
                break;
              // FUTURE: delete with editorjs
              case BlockEditorTypesEnum.H2:
                blockType = BlockContentType.Header;
                break;
              // FUTURE: delete with editorjs
              case BlockEditorTypesEnum.H3:
                blockType = BlockContentType.Header;
                break;
              // FUTURE: delete with editorjs
              case BlockEditorTypesEnum.H4:
                blockType = BlockContentType.Header;
                break;

              case BlockEditorTypesEnum.HEADING:
                blockType = BlockContentType.Header;
                break;
              case BlockEditorTypesEnum.TABLE:
                blockType = BlockContentType.Table;
                break;

              // FUTURE: delete with editorjs
              case BlockEditorTypesEnum.LIST:
                blockType = BlockContentType.List;
                break;

              case BlockEditorTypesEnum.ORDERED_LIST:
                blockType = BlockContentType.Orderedlist;
                break;
              case BlockEditorTypesEnum.BULLET_LIST:
                blockType = BlockContentType.Bulletlist;
                break;
              case BlockEditorTypesEnum.CODE:
                blockType = BlockContentType.Code;
                break;
              case BlockEditorTypesEnum.CHECKLIST:
                blockType = BlockContentType.Checklist;
                break;
              case BlockEditorTypesEnum.IMAGE:
                blockType = BlockContentType.Image;
                delete data.attrs?.['fileUrl'];
                break;
              case BlockEditorTypesEnum.IMAGE_BLOCK:
                blockType = BlockContentType.Image;
                delete data.attrs?.['src'];
                break;
              case BlockEditorTypesEnum.EMBED:
                blockType = BlockContentType.Embed;
                break;
              case BlockEditorTypesEnum.PRACTICAL_TIPS:
                blockType = BlockContentType.PracticalTips;
                break;
              case BlockEditorTypesEnum.KEY_TAKEAWAYS:
                blockType = BlockContentType.KeyTakeaways;
                break;
              case BlockEditorTypesEnum.WARNING:
                blockType = BlockContentType.Warning;
                break;
              case BlockEditorTypesEnum.EXAMPLE:
                blockType = BlockContentType.Example;
                break;
              // FUTURE: delete with editorjs
              case BlockEditorTypesEnum.DIVIDER:
              case BlockEditorTypesEnum.HORIZONTAL_RULE:
                blockType = BlockContentType.Divider;
                break;

              case BlockEditorTypesEnum.DISCUSSION_PROMPT:
                blockType = BlockContentType.DiscussionPrompt;
                break;
              case BlockEditorTypesEnum.ARTICLE:
                blockType = BlockContentType.Article;
                break;
              case BlockEditorTypesEnum.DOCUMENT_BLOCK:
                blockType = BlockContentType.Document;
                delete data.attrs?.['documentsEnabled'];
                delete data.attrs?.['src'];
                break;
              case BlockEditorTypesEnum.COLUMNS:
                blockType = BlockContentType.Columns;
                break;
              case BlockEditorTypesEnum.BLOCK_QUOTE:
                blockType = BlockContentType.Blockquote;
                break;
              default:
                Sentry.captureException(new Error('Editor block without valid type'), {
                  extra: {
                    blockType: b.type,
                    blockId: b.attrs?.['id'],
                    stepId: step?.id,
                  },
                });
                blockType = undefined;
            }

            return blockType
              ? {
                  id: b.attrs?.['id'] ?? '',
                  editorVersion: EditorVersion.Tiptap,
                  content: JSON.stringify(data),
                  type: blockType,
                }
              : null;
          })
          .filter((b) => !!b);

        onSave({
          title,
          blocks,
          headerImage: headerImage?.fileId
            ? {
                fileId: headerImage.fileId,
                isFullWidth: headerImage.isFullWidth,
              }
            : undefined,
        });
      }
      setTitle(title ?? '');
    });
  }, [actions, headerImage, onSave, setSaveFunc, step?.id, title]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const blockCurrData = editorRef.current?.getJSON().content;

      // Newly created step check
      if (
        step?.title === undefined &&
        blocks.content &&
        blocks.content.length === 0 &&
        // blockCurrData?.[0].data.text === '' &&
        (title === undefined || title === '')
      ) {
        return setIsDirty(false);
      }

      // if (
      //   title !== step?.title ||
      //   (blockCurrData && !compareObjArrays(blockCurrData, blocks.content))
      // ) {
      //   return setIsDirty(true);
      // } else {
      //   return setIsDirty(false);
      // }
    }, 5 * 1000);

    return () => clearInterval(interval);
  }, [blocks, step?.title, title]);

  useLearningActivityState({ isDirty, isValid: !!title });

  const analyseArticleUrl = useCallback(
    (url: string) => actions.block.analyseArticleUrl(url, courseId),
    [actions.block, courseId],
  );

  const {
    query: { data: company },
  } = useCurrentCompany(defaultGraphqlClient);

  if (!company) {
    return null;
  }

  return (
    <>
      <div
        className="w-full"
        id="block-step-edit-container"
      >
        <ScrollContainer scrollOnDesktop={true}>
          <HeaderImage
            value={headerImage}
            companyId={company.id}
            onChange={(e) => setHeaderImage(e)}
            isEditing={true}
          />
          <ContentWrapper className="mx-auto pb-6 pt-4">
            <EditHeader
              value={title ?? ''}
              onChangeTitle={(e) => setTitle(e.target.value)}
              placeholder={t('title-placeholder')}
              error={title === '' ? tValidation('required') : undefined}
            />
          </ContentWrapper>
          <BlockEditorV3.BlockEditor
            ref={editorRef}
            className="mt-8"
            id="content-step-block-editor"
            placeholder={t('block-editor.placeholder')}
            data={blocks}
            articleUrlAnalyserFunc={analyseArticleUrl}
            documentsEnabled={documentsEnabled}
            companyId={company.id}
            onSupportClick={actions.openSupport}
          />
        </ScrollContainer>
      </div>
      <ArticleAISidebar
        isOpen={isArticleAISidebarOpen}
        onClose={() => setIsArticleAISidebarOpen(false)}
        findArticles={(searchQuery: string) =>
          actions.block.searchStepArticles(courseId, searchQuery)
        }
        startArticleSearchTermGeneration={() =>
          step
            ? actions.block.startArticleSearchTermGeneration(
                courseId,
                moduleId,
                learningActivityId,
                step.id,
              )
            : Promise.resolve(false)
        }
        getSuggestedArticleSearchTerm={() =>
          step
            ? actions.block.getSuggestedArticleSearchTerm(
                courseId,
                moduleId,
                learningActivityId,
                step.id,
              )
            : Promise.resolve({ status: SuggestionGenerationStatus.None })
        }
        onAdd={(articles) => {
          for (const article of articles) {
            editorRef.current
              ?.chain()
              .focus('end')
              .insertContent({
                type: 'article',
                attrs: {
                  link: article.url,
                  source: article.source,
                  faviconUrl: article.iconUrl,
                  title: article.title,
                  description: article.description,
                },
              })
              .run();
          }

          scrollToEditorBottom();
        }}
      />
      <VideoAISidebar
        isOpen={isVideoAISidebarOpen}
        onClose={() => setIsVideoAISidebarOpen(false)}
        findVideos={(searchQuery: string) => actions.block.searchStepVideos(courseId, searchQuery)}
        startVideoSearchTermGeneration={() =>
          step
            ? actions.block.startVideoSearchTermGeneration(
                courseId,
                moduleId,
                learningActivityId,
                step.id,
              )
            : Promise.resolve(false)
        }
        getSuggestedVideoSearchTerm={() =>
          step
            ? actions.block.getSuggestedVideoSearchTerm(
                courseId,
                moduleId,
                learningActivityId,
                step.id,
              )
            : Promise.resolve({ status: SuggestionGenerationStatus.None })
        }
        onAdd={(videos) => {
          for (const video of videos) {
            editorRef.current
              ?.chain()
              .focus('end')
              .insertContent({
                type: 'embed',
                attrs: {
                  src: video.url,
                  remoteId: BlockEditorV3.matchAndCalculateRemoteId(video.url, 'youtube'),
                  embedType: 'youtube',
                },
                content: [{ type: 'paragraph', content: [{ type: 'text', text: video.title }] }],
              })
              .run();
          }

          scrollToEditorBottom();
        }}
      />
      <ImageAISidebar
        isOpen={isImageAISidebarOpen}
        courseId={courseId}
        onClose={() => setIsImageAISidebarOpen(false)}
        currentCourse={currentCourse}
        onAdd={async (suggestion) => {
          try {
            const uploader = fileUploader(getUploadUrl, uploadFile, getFileUrl);
            const res = await uploader.uploadByUrl(suggestion.fullURL, company.id);
            editorRef.current
              ?.chain()
              .focus('end')
              .insertContent({
                type: 'image',

                attrs: {
                  src: res.url,
                },
              })
              .run();
          } catch (error) {
            console.log(error);
          }
        }}
        startContentEntitiesGeneration={async () => {
          if (step?.id && actions?.block.startContentEntitiesGeneration) {
            return await actions.block.startContentEntitiesGeneration({
              input: {
                courseId,
                moduleId,
                learningActivityId,
                learningActivityStepId: step.id,
              },
            });
          }
          return Promise.resolve(false);
        }}
        fetchContentEntitiesSuggestions={async () => {
          if (step?.id && actions?.block.fetchSuggestedContentEntities) {
            return await actions?.block?.fetchSuggestedContentEntities?.({
              nrPerPage: 3,
              courseId,
              moduleId,
              learningActivityStepId: step.id,
              learningActivityId,
            });
          }
          return Promise.reject();
        }}
        fetchSuggestedImages={(variables) =>
          actions?.block.fetchSuggestedImages?.(variables).then(
            (res) =>
              res.suggestedImages.suggestions
                ?.map((s) =>
                  s.id && s.fullUrl && s.thumbnailUrl && s?.creator?.name
                    ? {
                        ...s,
                        fullURL: s.fullUrl,
                        thumbURL: s.thumbnailUrl,
                        creator: {
                          ...s.creator,
                          name: s.creator.name,
                          url: s.creator?.url ?? '',
                        },
                        provider: {
                          name: s.provider?.name ?? '',
                          url: s.provider?.url ?? '',
                        },
                      }
                    : undefined,
                )
                .flatMap((s) => (s ? [s] : [])) ?? [],
          ) ?? Promise.reject()
        }
      />
    </>
  );
};

export default BlockStepEdit;

function scrollToEditorBottom() {
  const container = document.getElementById('block-step-edit-container');
  if (container) {
    container.scrollTop = container.scrollHeight;
  }
}
