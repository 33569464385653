import { LearningActivityStep } from '@stellar-lms-frontend/lms-api';
import { EditHeader } from '../learning-activity/components/edit-header';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  AddCard,
  CardDuringDrag,
  ContentWrapper,
  DragVerticalList,
  FillBlanksSettingsCard,
  FloatingOptions,
  Form,
  HeaderImage,
  Heading4,
  ImageSettings,
  ListUnordered3Icon,
  parseFITBTextFromAnswers,
  QuizMultipleChoiceSettingsCard,
  ScrollContainer,
  StarIcon,
  TIcon,
} from '@stellar-lms-frontend/ui-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  AssessmentFillBlanksErrorType,
  AssessmentMultipleChoiceErrorType,
  AssessmentQuestionTypeNamesEnum,
  AssessmentQuestionTypes,
  assessmentFieldsSchemas,
  transformAssessmentQuestionsToSubmit,
} from './util-functions';
import { UniqueIdentifier } from '@dnd-kit/core';
import * as Sentry from '@sentry/react';
import {
  LearningActivityContext,
  QUIZ,
  STEP_URI_CREATE_CONST,
  SUGGESTED_QUESTIONS,
} from '../learning-activity';
import { useLearningActivityState } from '../learning-activity/hooks/use-learning-activity-state';
import { AssessmentAIStepEdit } from './components/assessment-ai-step-edit';
import {
  CreateQuizStepMutation,
  DesignerAssessmentStepQuery,
  DesignerAssessmentStepQueryVariables,
  MarkSuggestedQuestionAsAcceptedMutation,
  MarkSuggestedQuestionAsAcceptedMutationVariables,
  MarkTipViewedMutationVariables,
  MutationCreateQuizStepArgs,
  MutationUpdateQuizStepArgs,
  QuestionGenerationMutationVariables,
  QuizStepInput,
  SuggestedQuestions,
  SuggestedQuestionsQueryVariables,
  UpdateQuizStepMutation,
  useCurrentCompany,
} from '@stellar-lms-frontend/lms-graphql';
import { AIStepEditI18N } from '../ai-step-edit/ai-step-edit';
import { AssessmentStepViewProps } from './assessment-step-view';
import { useAISuggestions } from '../ai-step-edit/use-ai-suggestions';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDefaultHeaderImage } from '../../hooks';
import { useParams } from 'react-router-dom';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';

type FormData = {
  title: string;
  questions: AssessmentQuestionTypes[];
  headerImage?: ImageSettings;
};

type Actions = {
  edit?: {
    markTipViewed?: (variables: MarkTipViewedMutationVariables) => void;
  };
  assessment?: {
    create?: (variables: MutationCreateQuizStepArgs) => Promise<CreateQuizStepMutation>;
    update?: (variables: MutationUpdateQuizStepArgs) => Promise<UpdateQuizStepMutation>;
    fetch?: (
      variables: DesignerAssessmentStepQueryVariables,
    ) => Promise<DesignerAssessmentStepQuery>;
    fetchSuggestedQuestions?: (
      variables: SuggestedQuestionsQueryVariables,
    ) => Promise<SuggestedQuestions>;
    getDefaultHeaderImage?: (courseId: string) => Promise<ImageSettings | undefined>;
    questionGeneration?: (variables: QuestionGenerationMutationVariables) => Promise<boolean>;
    markSuggestedQuestionAsAccepted?: (
      variables: MarkSuggestedQuestionAsAcceptedMutationVariables,
    ) => Promise<MarkSuggestedQuestionAsAcceptedMutation>;
  };
  publishedAssessment: AssessmentStepViewProps['actions'];
};

export type AssessmentStepEditI18N = {
  aiStepEdit: AIStepEditI18N;
};

export type AssessmentStepEditProps = {
  courseId: string;
  moduleId: string;
  learningActivityId: string;
  step?: LearningActivityStep;
  actions: Actions;
};

export const AssessmentStepEdit: React.FC<AssessmentStepEditProps> = ({
  courseId,
  moduleId,
  learningActivityId,
  step,
  actions,
}: AssessmentStepEditProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assessment-step-view' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });
  const { stepId } = useParams();

  const queryClient = useQueryClient();
  const schema = yup.object({
    title: yup.string().required(tValidation('required')),
    questions: yup
      .array()
      .of(
        yup.lazy(
          (field: { __typename: AssessmentQuestionTypeNamesEnum }) =>
            assessmentFieldsSchemas({ required: tValidation('required') })[field.__typename],
        ),
      ),
  });

  const { setSaveFunc } = useContext(LearningActivityContext);
  const [openCardIndex, setOpenCardIndex] = useState<UniqueIdentifier | undefined>(undefined);
  const [isSelectingQuestionType, setIsSelectingQuestionType] = useState(false);
  const [acceptedSuggestions, setAcceptedSuggestions] = useState<Set<string>>(new Set());
  const [isAISidebarOpen, setIsAISidebarOpen] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<FormData>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      title: step?.title,
      questions: [],
      headerImage: step?.headerImage,
    },
  });

  useDefaultHeaderImage(
    stepId,
    () => actions.assessment?.getDefaultHeaderImage?.(courseId) ?? Promise.resolve(undefined),
    (headerImage: ImageSettings) => {
      setValue('headerImage', headerImage);
    },
  );

  const { fields, append, replace, move } = useFieldArray({
    control,
    name: 'questions',
  });

  const isQueryEnabled = !!(learningActivityId && step?.id && step.id !== STEP_URI_CREATE_CONST);
  useQuery(
    [learningActivityId, step?.id, QUIZ],
    () =>
      isQueryEnabled
        ? (actions?.assessment?.fetch?.({ learningActivityId, stepId: step.id }) ?? null)
        : null,
    {
      enabled: isQueryEnabled,
      refetchOnWindowFocus: false,

      onSuccess: (data) => {
        let questions: AssessmentQuestionTypes[] | undefined = undefined;

        if (data?.learningActivityStep && data.learningActivityStep.__typename === 'QuizStep') {
          questions = data.learningActivityStep.questions?.map((question) => {
            if (question.__typename === 'QuizFillInTheBlanksQuestion') {
              return {
                ...question,
                text: parseFITBTextFromAnswers(question.text, question.answers ?? []),
              };
            } else {
              return question;
            }
          });
        }

        reset({
          title: step?.title,
          questions: questions ?? [],
          headerImage: step?.headerImage,
        });
      },
    },
  );

  const onDeleteQuestion = useCallback(
    (questionId: string) => {
      replace(fields.filter((f) => f.id !== questionId));
      setAcceptedSuggestions((prev) => {
        prev.delete(questionId);
        return new Set(prev);
      });
    },
    [fields, replace],
  );

  useLearningActivityState({ isDirty, isValid });

  const {
    suggestions: suggestedQuestions,
    status: suggestedQuestionsStatus,
    fetchNextPage,
    hasNextPage,
  } = useAISuggestions({
    queryKey: [learningActivityId, step?.id, QUIZ, SUGGESTED_QUESTIONS],
    fetchFunction: actions?.assessment?.fetchSuggestedQuestions,
    courseId,
    moduleId,
  });

  const remainingSuggestedQuestions = suggestedQuestions.filter(
    (sQ) => !acceptedSuggestions.has(sQ.id),
  );

  const onSave = useCallback(
    async (data: QuizStepInput, acceptedSuggestions: Set<string>) => {
      if (step?.id && step?.id !== STEP_URI_CREATE_CONST) {
        await actions?.assessment?.update?.({
          learningActivityId,
          stepId: step.id,
          input: data,
        });
      } else {
        await actions?.assessment?.create?.({
          learningActivityId,
          input: data,
        });
      }

      acceptedSuggestions.forEach((suggestedQuestionId) => {
        actions?.assessment
          ?.markSuggestedQuestionAsAccepted?.({ courseId, suggestedQuestionId })
          .then();
      });

      // CLEANUP: optimistic update to avoid UI delay
      queryClient.invalidateQueries([learningActivityId, step?.id, QUIZ]);
    },

    [actions?.assessment, learningActivityId, queryClient, step?.id],
  );
  useEffect(() => {
    setSaveFunc?.(() =>
      handleSubmit((data) => {
        onSave(
          {
            ...data,
            headerImage: data.headerImage?.fileId
              ? { fileId: data.headerImage.fileId, isFullWidth: data.headerImage?.isFullWidth }
              : undefined,
            questions: transformAssessmentQuestionsToSubmit(data.questions),
          },
          acceptedSuggestions,
        );
      }),
    );
  }, [acceptedSuggestions, handleSubmit, onSave, setSaveFunc]);

  const startQuestionGeneration = useCallback(async () => {
    return !!(
      moduleId &&
      courseId &&
      (await actions?.assessment?.questionGeneration?.({ input: { moduleId, courseId } }))
    );
  }, [actions?.assessment, courseId, moduleId]);

  const {
    query: { data: company },
  } = useCurrentCompany(defaultGraphqlClient);

  if (!company) {
    return null;
  }

  return (
    <ScrollContainer scrollOnDesktop={true}>
      <Form className="mb-6 grow ">
        <Controller
          control={control}
          name="headerImage"
          render={({ field }) => (
            <HeaderImage
              companyId={company.id}
              {...field}
              isEditing={true}
            />
          )}
        />
        <ContentWrapper className="font-lexend mx-auto space-y-4 pb-6 pt-4">
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <EditHeader
                {...field}
                onChangeTitle={(e) => field.onChange(e.target.value)}
                placeholder={t('edit.step-title-placeholder')}
                error={errors.title?.message}
              />
            )}
          />

          <Heading4 className="text-text-01">{t('edit.title')}</Heading4>
          <AssessmentAIStepEdit
            isOpen={isAISidebarOpen}
            onClose={() => setIsAISidebarOpen(false)}
            suggestions={remainingSuggestedQuestions}
            fetchMoreSuggestions={fetchNextPage}
            suggestionsStatus={suggestedQuestionsStatus}
            hasMoreSuggestions={hasNextPage}
            onAdd={(addedQuestions, acceptedSuggestions) => {
              addedQuestions.forEach((aQ) => append(aQ));
              acceptedSuggestions.forEach((id) => {
                setAcceptedSuggestions((prev) => prev.add(id));
              });
            }}
            startSuggestionsGeneration={startQuestionGeneration}
            setIsAISidebarOpen={setIsAISidebarOpen}
            markTipViewed={actions?.edit?.markTipViewed}
          />
          <DragVerticalList
            items={fields}
            listClassName="space-y-3"
            onChange={(_, prevPos, newPos) => {
              move(prevPos, newPos);
              setOpenCardIndex(undefined);
            }}
          >
            {({ item: q, setActivatorNodeRef, listeners, dragActive, index }) => (
              <Controller
                key={q.id}
                control={control}
                name={`questions.${index}`}
                render={({ field, fieldState }) => {
                  if (dragActive) {
                    return <CardDuringDrag title={field.value.text ?? ''} />;
                  }

                  switch (field.value.__typename) {
                    case AssessmentQuestionTypeNamesEnum.QUIZ_CHOICE_QUESTION:
                      return (
                        <QuizMultipleChoiceSettingsCard
                          value={{
                            ...field.value,
                            answers: field.value.answers ?? [],
                          }}
                          onChange={(value) =>
                            field.onChange({
                              __typename: field.value.__typename,
                              id: field.value.id,
                              ...value,
                            })
                          }
                          isOpen={openCardIndex === index}
                          onToggleOpenState={() =>
                            setOpenCardIndex(() => (openCardIndex === index ? undefined : index))
                          }
                          dragProps={{ setActivatorNodeRef, listeners, dragActive }}
                          onDelete={() => onDeleteQuestion(q.id)}
                          errors={fieldState.error as AssessmentMultipleChoiceErrorType}
                        />
                      );
                    case AssessmentQuestionTypeNamesEnum.QUIZ_FILL_IN_THE_BLANKS_QUESTION:
                      return (
                        <FillBlanksSettingsCard
                          value={{ ...field.value }}
                          onChange={(value) =>
                            field.onChange({
                              __typename: field.value.__typename,
                              id: field.value.id,
                              ...value,
                            })
                          }
                          isOpen={openCardIndex === index}
                          onToggleOpenState={() =>
                            setOpenCardIndex(() => (openCardIndex === index ? undefined : index))
                          }
                          dragProps={{ setActivatorNodeRef, listeners, dragActive }}
                          onDelete={() => onDeleteQuestion(q.id)}
                          errors={fieldState.error as AssessmentFillBlanksErrorType}
                        />
                      );
                    default:
                      Sentry.captureException(new Error('Assessment question without __typename'), {
                        extra: {
                          stepId: step?.id,
                          questionId: q.id,
                        },
                      });
                      return <> </>;
                  }
                }}
              />
            )}
          </DragVerticalList>
          <FloatingOptions
            isOpen={isSelectingQuestionType}
            onClose={() => setIsSelectingQuestionType(false)}
            placement="bottom-start"
            widthClassName="w-[600px] lg:w-[640px] 2xl:w-[720px]"
            wrappedComponent={
              <div>
                <AddCard
                  isEnabled
                  onClick={() => setIsSelectingQuestionType(true)}
                  label={t('edit.add-question')}
                />
              </div>
            }
            options={[
              {
                label: <span className="text-purple-800">{tGeneral('ai-generate')}</span>,
                left: <StarIcon className="text-purple-800" />,
                onClick: () => {
                  setIsAISidebarOpen(true);
                  setIsSelectingQuestionType(false);
                },
              },
              {
                label: t('question-types.fill-blanks.name'),
                left: <TIcon className="text-text-01" />,
                onClick: () => {
                  setOpenCardIndex(fields.length);
                  append({
                    __typename: AssessmentQuestionTypeNamesEnum.QUIZ_FILL_IN_THE_BLANKS_QUESTION,
                    answers: [],
                    feedback: '',
                    spacedRepetitionEnabled: true,
                    text: '',
                  });
                  setIsSelectingQuestionType(false);
                },
              },
              {
                label: t('question-types.choice.name'),
                left: <ListUnordered3Icon className="text-text-01" />,
                onClick: () => {
                  setOpenCardIndex(fields.length);
                  append({
                    __typename: AssessmentQuestionTypeNamesEnum.QUIZ_CHOICE_QUESTION,
                    answers: [],
                    feedback: '',
                    spacedRepetitionEnabled: true,
                    text: '',
                    multiple: false,
                  });
                  setIsSelectingQuestionType(false);
                },
              },
            ]}
          />
        </ContentWrapper>
      </Form>
    </ScrollContainer>
  );
};

export default AssessmentStepEdit;
