import { useMemo } from 'react';

import Paragraph from '@tiptap/extension-paragraph';
import Heading from '@tiptap/extension-heading';
import Text from '@tiptap/extension-text';
import FileHandler from '@tiptap-pro/extension-file-handler';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import CodeBlock from '@tiptap/extension-code-block';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import BulletList from '@tiptap/extension-bullet-list';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Blockquote from '@tiptap/extension-blockquote';
import Strike from '@tiptap/extension-strike';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Underline from '@tiptap/extension-underline';
import Color from '@tiptap/extension-color';
import Link from '@tiptap/extension-link';
import Code from '@tiptap/extension-code';
import Highlight from '@tiptap/extension-highlight';
import DropCursor from '@tiptap/extension-dropcursor';
import Focus from '@tiptap/extension-focus';
import History from '@tiptap/extension-history';
import TextStyle from '@tiptap/extension-text-style';
import UniqueID from '@tiptap-pro/extension-unique-id';

import Details from '@tiptap-pro/extension-details';
import DetailsContent from '@tiptap-pro/extension-details-content';
import DetailsSummary from '@tiptap-pro/extension-details-summary';

import { Example } from './extensions/example';
import { Warning } from './extensions/warning';
import { KeyTakeAways } from './extensions/key-takeaways';
import { PracticalTips } from './extensions/practical-tips';
import { DiscussionPrompt } from './extensions/discussion-prompt';
import { Article } from './extensions/article';
import { Embed } from './extensions/embed';

import { Image } from './extensions/image';
import { ImageBlock } from './extensions/image-block';
import { ImageUpload } from './extensions/image-upload';

// functionality extensions
import TextAlign from '@tiptap/extension-text-align';
import ListKeyMap from '@tiptap/extension-list-keymap';
import Placeholder from '@tiptap/extension-placeholder';

import { SlashCommand } from './extensions/SlashCommand';
import { DocumentBlock } from './extensions/document-block';
import { DocumentUpload } from './extensions/document-upload';
import { SupportPopup } from './extensions/support-popup';
import { Column, Columns } from './extensions/multi-column';
import { Table, TableCell, TableHeader, TableRow } from './extensions/table';
import { Document } from './extensions/document';
import { useTranslation } from 'react-i18next';

export const useExtensions = ({
  placeholder,
  companyId,
  documentsEnabled,
  onOpenDiscussionClick,
  onSupportClick,
}: {
  placeholder?: string;
  companyId: string;
  documentsEnabled: boolean;
  onOpenDiscussionClick: () => void;
  onSupportClick: () => void;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.tiptap.slash-command' });

  const i18n = useMemo(
    () => ({
      h1: {
        title: t('h1.title'),
      },
      h2: {
        title: t('h2.title'),
      },
      h3: {
        title: t('h3.title'),
      },
      h4: {
        title: t('h4.title'),
      },
      bulletList: {
        title: t('bulletList.title'),
        description: t('bulletList.description'),
      },
      numberedList: {
        title: t('numberedList.title'),
        description: t('numberedList.description'),
      },
      codeBlock: {
        title: t('codeBlock.title'),
      },
      discussion: {
        title: t('discussion.title'),
        description: t('discussion.description'),
      },
      example: {
        title: t('example.title'),
        description: t('example.description'),
      },
      image: {
        title: t('image.title'),
        description: t('image.description'),
      },
      table: {
        title: t('table.title'),
        description: t('table.description'),
      },
      taskList: {
        title: t('taskList.title'),
        description: t('taskList.description'),
      },
      warning: {
        title: t('warning.title'),
        description: t('warning.description'),
      },
      keyTakeAways: {
        title: t('keyTakeAways.title'),
        description: t('keyTakeAways.description'),
      },
      practicalTips: {
        title: t('practicalTips.title'),
        description: t('practicalTips.description'),
      },
      horizontalRule: {
        title: t('horizontalRule.title'),
        description: t('horizontalRule.description'),
      },
      document: {
        title: t('document.title'),
        description: t('document.description'),
      },
      columns: {
        title: t('columns.title'),
        description: t('columns.description'),
      },
    }),
    [t],
  );
  const extensions = useMemo(
    () => [
      Document,
      Paragraph.configure({
        HTMLAttributes: {
          class: 'font-lexend text-text-01 text-left mt-1 w-full outline-none content-editable',
        },
      }),
      Heading.configure({
        levels: [1, 2, 3, 4],
        HTMLAttributes: {
          class: 'font-lexend text-text-01 mb-2 mt-4 outline-none',
        },
      }),
      Text,
      HorizontalRule.configure({
        HTMLAttributes: {
          class: 'my-8 w-full',
        },
      }),

      Strike,
      Bold,
      Italic,
      Subscript,
      Superscript,
      Code,
      Underline,
      Link,
      Color,
      Highlight.configure({ multicolor: true }),
      TextStyle,
      Column,
      Columns,

      ListItem,
      OrderedList,
      BulletList,
      Article,

      DocumentBlock(documentsEnabled),
      DocumentUpload(documentsEnabled),

      FileHandler.configure({
        allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
        onDrop: (currentEditor, files, pos) => {
          files.forEach((file) => {
            const fileReader = new FileReader();

            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
              currentEditor
                .chain()
                .insertContentAt(pos, {
                  type: 'image',
                  attrs: {
                    src: fileReader.result,
                  },
                })
                .focus()
                .run();
            };
          });
        },
        onPaste: (currentEditor, files, htmlContent) => {
          files.forEach((file) => {
            if (htmlContent) {
              // if there is htmlContent, stop manual insertion & let other extensions handle insertion via inputRule
              // you could extract the pasted file from this url string and upload it to a server for example
              console.log(htmlContent); // eslint-disable-line no-console
              return false;
            }

            const fileReader = new FileReader();

            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
              currentEditor
                .chain()
                .insertContentAt(currentEditor.state.selection.anchor, {
                  type: 'image',
                  attrs: {
                    src: fileReader.result,
                  },
                })
                .focus()
                .run();
            };

            return true;
          });
        },
      }),

      Details.configure({
        persist: true,
      }),
      DetailsSummary,
      DetailsContent,

      Blockquote.configure({
        HTMLAttributes: {
          class: 'my-4',
        },
      }),

      TaskList,
      TaskItem.configure({
        nested: true,
        onReadOnlyChecked: () => true,
      }),

      CodeBlock.configure({
        HTMLAttributes: {
          class: 'bg-surface-bg p-6 my-4 text-text-01 font-lexend rounded-xl',
        },
      }),

      // Discussion prompt
      DiscussionPrompt(onOpenDiscussionClick),

      // Table
      Table.configure({
        HTMLAttributes: {
          class: '!my-4',
        },
      }),
      TableHeader,
      TableRow,
      TableCell,

      Example,
      Warning,
      KeyTakeAways,
      Embed,

      // Image.configure({
      //   allowBase64: true,
      // }),
      ImageBlock.configure({
        allowBase64: true,
      }),
      ImageUpload.configure({
        companyId: companyId,
      }),

      PracticalTips,

      // Functionality extensions
      ...(placeholder
        ? [
            Placeholder.configure({
              placeholder: placeholder,
            }),
          ]
        : []),
      ListKeyMap.configure({
        listTypes: [
          {
            itemName: 'listItem',
            wrapperNames: ['bulletList', 'orderedList'],
          },
          {
            itemName: 'taskItem',
            wrapperNames: ['taskList'],
          },
          {
            itemName: 'listItem',
            wrapperNames: ['practicalTips'],
          },
        ],
      }),
      Focus,
      SupportPopup(onSupportClick),
      SlashCommand(companyId, documentsEnabled, onSupportClick, i18n),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      DropCursor,
      UniqueID.configure({
        types: [
          Image.name,
          ImageBlock.name,
          Embed.name,
          Example.name,
          KeyTakeAways.name,
          BulletList.name,
          OrderedList.name,
          PracticalTips.name,
          Warning.name,
          Table.name,
          DiscussionPrompt(() => null).name,
          CodeBlock.name,
          TaskList.name,
          Blockquote.name,
          Details.name,
          Paragraph.name,
          Heading.name,
          DocumentBlock(false).name,
          Article.name,
          HorizontalRule.name,
        ],
      }),
      History,
    ],
    [documentsEnabled, companyId, placeholder, onOpenDiscussionClick, onSupportClick],
  );

  return extensions;
};
