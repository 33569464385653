import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { BaseCard } from '../../../card';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ArticleComponent = (props: NodeViewProps) => {
  const link = props.node.attrs['link'];
  const title = props.node.attrs['title'];
  const description = props.node.attrs['description'];
  const faviconUrl = props.node.attrs['faviconUrl'];
  const source = props.node.attrs['source'];

  return (
    <NodeViewWrapper className="article-node-view">
      <BaseCard
        className="my-2"
        childrenClassName="p-4"
        onClick={() => window.open(link, '_blank')}
      >
        <div className="space-y-2">
          <div className="flex space-x-2">
            {faviconUrl && (
              <img
                className="h-6 w-6 shrink-0"
                src={faviconUrl}
                alt="favicon"
              />
            )}

            <div className="type-body-medium text-text-01 font-lexend line-clamp-1 grow">
              {title}
            </div>
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className="text-md cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                window.open(link, '_blank');
              }}
            />
          </div>

          {description && (
            <div className="type-tiny font-lexend text-text-02 line-clamp-3">{description}</div>
          )}
          <div className="type-tiny font-lexend text-text-02">{source}</div>
        </div>
      </BaseCard>
    </NodeViewWrapper>
  );
};
