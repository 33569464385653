import DragHandle from '@tiptap-pro/extension-drag-handle-react';
import { Editor } from '@tiptap/react';

import useContentItemActions from './hooks/useContentItemActions';
import { useData } from './hooks/useData';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faPlusCircle, faTrash } from '@fortawesome/pro-light-svg-icons';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { Toolbar } from '../../ui/Toolbar';
import { Surface } from '../../ui/Surface';
import { DropdownButton } from '../../ui/Dropdown';
import * as Popover from '@radix-ui/react-popover';

export type ContentItemMenuProps = {
  editor: Editor;
};

export const ContentItemMenu = ({ editor }: ContentItemMenuProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const data = useData();
  const actions = useContentItemActions(editor, data.currentNode, data.currentNodePos);

  useEffect(() => {
    if (menuOpen) {
      editor.commands.setMeta('lockDragHandle', true);
    } else {
      editor.commands.setMeta('lockDragHandle', false);
    }
  }, [editor, menuOpen]);

  if (!editor.isEditable) {
    return null;
  }

  return (
    <DragHandle
      pluginKey="ContentItemMenu"
      editor={editor}
      onNodeChange={data.handleNodeChange}
      tippyOptions={{
        offset: [-2, 16],
        zIndex: 99,
      }}
    >
      <div className="flex items-center gap-1 pt-1">
        <Toolbar.Button onClick={actions.handleAdd}>
          <FontAwesomeIcon
            icon={faPlusCircle}
            className="text-xl"
          />
        </Toolbar.Button>
        <Popover.Root
          open={menuOpen}
          onOpenChange={setMenuOpen}
        >
          <Popover.Trigger asChild>
            <Toolbar.Button>
              <FontAwesomeIcon
                icon={faGripDotsVertical}
                className="text-xl"
              />
            </Toolbar.Button>
          </Popover.Trigger>
          <Popover.Content
            side="bottom"
            align="start"
            sideOffset={8}
          >
            {/* <Surface className="p-2 flex flex-col gap-1 min-w-[16rem]"> */}
            <div className="grid grid-cols-1 gap-1">
              <Popover.Close>
                <DropdownButton onClick={actions.moveUp}>
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    className="text-xl"
                  />
                  Move up
                </DropdownButton>
                <DropdownButton
                  onClick={actions.deleteNode}
                  className="text-negative-01"
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="text-xl text-negative-01"
                  />
                  Delete
                </DropdownButton>
                <DropdownButton onClick={actions.moveDown}>
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    className="text-xl"
                  />
                  Move down
                </DropdownButton>
              </Popover.Close>
              {/* </Surface> */}
            </div>
          </Popover.Content>
        </Popover.Root>
      </div>
    </DragHandle>
  );
};
