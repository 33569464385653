import { EditHeader } from '../learning-activity/components/edit-header';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  AlertCircleIcon,
  AutoResizeTextArea,
  ContentWrapper,
  Divider,
  Form,
  Heading4,
  Input,
  ScrollContainer,
  TabMenu,
} from '@stellar-lms-frontend/ui-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SurveyEditQuestions } from '../survey/survey-edit-questions';
import {
  CreateWbaStepMutation,
  MarkSuggestedActionAsAcceptedMutation,
  MarkSuggestedActionAsAcceptedMutationVariables,
  MarkTipViewedMutationVariables,
  MutationCreateWbaStepArgs,
  MutationUpdateWbaStepArgs,
  MutationWorkBasedActionGenerationArgs,
  QueryWbaStepQuery,
  SuggestedActions,
  SuggestedActionsQueryVariables,
  UpdateWbaStepMutation,
  useCurrentCompany,
  WbaStepInput,
} from '@stellar-lms-frontend/lms-graphql';
import {
  SurveyQuestionTypeNamesEnum,
  SurveyQuestionTypes,
  fieldSchemas,
  transformSurveyQuestionsToSubmit,
} from '../survey/survey-functions';
import {
  LearningActivityContext,
  STEP_URI_CREATE_CONST,
  SUGGESTED_QUESTIONS,
  WBA,
} from '../learning-activity';
import { useLearningActivityState } from '../learning-activity/hooks/use-learning-activity-state';
import { WbaAIStepEdit } from './components/wba-ai-sidebar/wba-ai-step-edit';
import { HeaderImage, ImageSettings } from '@stellar-lms-frontend/ui-components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAISuggestions } from '../ai-step-edit/use-ai-suggestions';
import { useTranslation } from 'react-i18next';
import { LEARNER_WBA_BY_SOURCE_ID } from './query';
import { useDefaultHeaderImage } from '../../hooks';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';

type FormData = {
  title: string;
  description: string;
  learnerShortDescription?: string;
  learnerDescription?: string;
  learnerQuestions?: SurveyQuestionTypes[];
  mentorQuestions?: SurveyQuestionTypes[];
  headerImage?: ImageSettings;
};

export type WBAStepEditActions = {
  edit?: {
    markTipViewed?: (variables: MarkTipViewedMutationVariables) => void;
  };
  wba?: {
    fetch: (learningActivityId: string, stepId: string) => Promise<QueryWbaStepQuery>;
    create?: (variables: MutationCreateWbaStepArgs) => Promise<CreateWbaStepMutation>;
    update?: (variables: MutationUpdateWbaStepArgs) => Promise<UpdateWbaStepMutation>;
    getDefaultHeaderImage?: (courseId: string) => Promise<ImageSettings | undefined>;
    actionGeneration?: (variables: MutationWorkBasedActionGenerationArgs) => Promise<boolean>;
    fetchSuggestedActions?: (
      variables: SuggestedActionsQueryVariables,
    ) => Promise<SuggestedActions>;
    markSuggestedActionAsAccepted?: (
      variables: MarkSuggestedActionAsAcceptedMutationVariables,
    ) => Promise<MarkSuggestedActionAsAcceptedMutation>;
  };
};

export type WBAEditStep = {
  id?: string;
  title?: string;
  description?: string;
  learnerShortDescription?: string;
  learnerDescription?: string;
  learnerQuestions: SurveyQuestionTypes[];
  mentorQuestions: SurveyQuestionTypes[];
  headerImage?: ImageSettings;
};

export type WBAStepEditProps = {
  stepId: string;
  learningActivityId: string;
  moduleId: string;
  courseId: string;
  actions: WBAStepEditActions;
};

enum Tabs {
  LEARNER = 'LEARNER',
  MENTOR = 'MENTOR',
}

export const WBAStepEdit: React.FC<WBAStepEditProps> = ({
  stepId,
  moduleId,
  courseId,
  learningActivityId,
  actions,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'work-based-actions.edit' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const queryClient = useQueryClient();
  const [wbaSourceId, setWbaSourceId] = useState<string | undefined>();

  const schema = yup.object({
    title: yup.string().required(tValidation('required')),
    //STE-2536 description: yup.string().required(tValidation('required')),
    learnerQuestions: yup
      .array()
      .of(
        yup.lazy(
          (field: { __typename: SurveyQuestionTypeNamesEnum }) =>
            fieldSchemas({ required: tValidation('required') })[field.__typename],
        ),
      ),
    mentorQuestions: yup
      .array()
      .of(
        yup.lazy(
          (field: { __typename: SurveyQuestionTypeNamesEnum }) =>
            fieldSchemas({ required: tValidation('required') })[field.__typename],
        ),
      ),
  });

  const useFormRet = useForm<FormData>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useFormRet;

  useDefaultHeaderImage(
    stepId,
    () =>
      courseId
        ? (actions.wba?.getDefaultHeaderImage?.(courseId) ?? Promise.resolve(undefined))
        : Promise.resolve(undefined),
    (headerImage: ImageSettings) => {
      setValue('headerImage', headerImage);
    },
  );

  const isQueryEnabled = stepId !== STEP_URI_CREATE_CONST;
  useQuery(
    [learningActivityId, stepId, WBA],
    () => (isQueryEnabled ? (actions.wba?.fetch(learningActivityId, stepId) ?? null) : null),
    {
      enabled: isQueryEnabled,
      refetchInterval: 0,
      // Uploading the header images is another browser window, this causes a refetch after upload
      // If we do not disable this refetch, all the form data will be lost.
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.learningActivityStep && data.learningActivityStep.__typename === 'WBAStep') {
          const { learningActivityStep } = data;
          setWbaSourceId(learningActivityStep.sourceId ?? undefined);

          reset({
            title: learningActivityStep?.title ?? undefined,
            description: learningActivityStep?.description ?? undefined,
            learnerShortDescription: learningActivityStep?.learnerShortDescription ?? undefined,
            learnerDescription: learningActivityStep?.learnerDescription ?? undefined,
            learnerQuestions: learningActivityStep?.learnerSurvey?.questions ?? [],
            mentorQuestions: learningActivityStep?.mentorSurvey?.questions ?? [],
            headerImage: learningActivityStep?.headerImage ?? undefined,
          });
        }
      },
    },
  );

  const {
    suggestions: suggestedActions,
    status: suggestedActionsStatus,
    fetchNextPage: fetchMoreSuggestions,
    hasNextPage: hasMoreSuggestions,
  } = useAISuggestions({
    queryKey: [learningActivityId, stepId, WBA, SUGGESTED_QUESTIONS],
    fetchFunction: actions?.wba?.fetchSuggestedActions,
    courseId,
    moduleId,
  });

  const startActionGeneration = useCallback(async () => {
    return !!(
      moduleId &&
      courseId &&
      actions?.wba?.actionGeneration?.({ input: { courseId, moduleId } })
    );
  }, [actions?.wba, courseId, moduleId]);

  const { setSaveFunc } = useContext(LearningActivityContext);
  const [acceptedSuggestion, setAcceptedSuggestion] = useState<string | undefined>(undefined);

  const [activeTabId, setActiveTabId] = useState<Tabs>(Tabs.LEARNER);
  const [isAISidebarOpen, setIsAISidebarOpen] = useState(false);

  useLearningActivityState({ isDirty, isValid });

  const save = useCallback(
    async (data: WbaStepInput, acceptedSuggestion?: string) => {
      if (!learningActivityId) return;

      if (stepId && stepId !== STEP_URI_CREATE_CONST) {
        await actions?.wba?.update?.({ learningActivityId, stepId, input: data });
        queryClient.invalidateQueries([LEARNER_WBA_BY_SOURCE_ID, stepId, wbaSourceId]);
      } else {
        await actions?.wba?.create?.({ learningActivityId, input: data });
      }

      acceptedSuggestion &&
        actions?.wba?.markSuggestedActionAsAccepted?.({
          courseId,
          suggestedActionId: acceptedSuggestion,
        });
    },
    [actions?.wba, courseId, learningActivityId, queryClient, stepId, wbaSourceId],
  );

  useEffect(() => {
    setSaveFunc?.(() =>
      handleSubmit((data) => {
        save(
          {
            title: data.title,
            description: data.description,
            learnerDescription: data.learnerDescription,
            learnerShortDescription: data.learnerShortDescription,
            learnerSurvey: {
              questions: transformSurveyQuestionsToSubmit(data.learnerQuestions ?? []),
            },
            mentorSurvey: {
              questions: transformSurveyQuestionsToSubmit(data.mentorQuestions ?? []),
            },
            headerImage: data.headerImage?.fileId
              ? {
                  fileId: data.headerImage.fileId,
                  isFullWidth: data.headerImage.isFullWidth,
                }
              : undefined,
          },
          acceptedSuggestion,
        );
      }),
    );
  }, [acceptedSuggestion, handleSubmit, save, setSaveFunc]);

  const {
    query: { data: company },
  } = useCurrentCompany(defaultGraphqlClient);

  if (!company) {
    return null;
  }

  return (
    <ScrollContainer scrollOnDesktop={true}>
      <FormProvider {...useFormRet}>
        <Form className="mb-5">
          <Controller
            control={control}
            name="headerImage"
            render={({ field }) => (
              <HeaderImage
                {...field}
                companyId={company.id}
                isEditing={true}
              />
            )}
          />
          <ContentWrapper className="mx-auto pb-6 pt-4">
            <div className="space-y-6">
              <Controller
                control={control}
                name="title"
                render={({ field }) => (
                  <EditHeader
                    {...field}
                    placeholder={t('title-placeholder')}
                    onChangeTitle={(e) => field.onChange(e.target.value)}
                    error={errors.title?.message}
                  />
                )}
              />
              <WbaAIStepEdit
                isOpen={isAISidebarOpen}
                hasConfirmOverwriteModal
                onClose={() => setIsAISidebarOpen(false)}
                suggestions={suggestedActions.map((s) => ({
                  ...s,
                  description: s.longDescription,
                  learnerShortDescription: s.shortDescription,
                }))}
                fetchMoreSuggestions={fetchMoreSuggestions}
                suggestionsStatus={suggestedActionsStatus}
                hasMoreSuggestions={hasMoreSuggestions}
                markTipViewed={actions.edit?.markTipViewed}
                onAdd={(addedActions, acceptedSuggestions) => {
                  reset({
                    title: addedActions[0].title,
                    description: addedActions[0].title,
                    learnerShortDescription: addedActions[0].learnerShortDescription,
                    learnerDescription: addedActions[0].description,
                    learnerQuestions: [],
                    mentorQuestions: [],
                  });
                  setAcceptedSuggestion(acceptedSuggestions.values().next().value);
                }}
                startSuggestionsGeneration={startActionGeneration}
                setIsAISidebarOpen={setIsAISidebarOpen}
              />
              <div className="space-y-4">
                <div className="font-lexend text-text-01 text-xl font-medium">{t('header')}</div>
                <div className="space-y-5">
                  {/*
                  STE-2536
                  <AutoResizeTextArea
                    minRows={1}
                    maxRows={3}
                    id="wba-description-input"
                    label={t('description')}
                    placeholder={t('description-placeholder')}
                    {...register('description')}
                    error={errors.description?.message}
                  />*/}
                  <Controller
                    control={control}
                    name="learnerShortDescription"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        htmlId="wba-learner-shortdescription-input"
                        label={t('learner-short-description')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder={t('learner-short-description-placeholder')}
                        error={errors.learnerShortDescription?.message}
                      />
                    )}
                  />
                  <AutoResizeTextArea
                    minRows={1}
                    maxRows={10}
                    id="wba-learner-description-input"
                    label={t('learner-description')}
                    placeholder={t('learner-description-placeholder')}
                    {...register('learnerDescription')}
                    error={errors.learnerDescription?.message}
                  />
                </div>
              </div>
            </div>
            <Divider className="my-8" />
            <Heading4 className="text-text-01 mb-6">{t('follow-up-survey')}</Heading4>
            <TabMenu
              className="mb-5"
              activeId={activeTabId}
              onChange={(id) => setActiveTabId(id)}
              type="horizontal"
              tabs={[
                {
                  id: Tabs.LEARNER,
                  label: (
                    <div className="flex items-center gap-2">
                      {errors.learnerQuestions && (
                        <AlertCircleIcon className="text-negative-01 h-5 w-5" />
                      )}
                      <span>{tGeneral('learner')}</span>
                    </div>
                  ),
                },
                {
                  id: Tabs.MENTOR,
                  label: (
                    <div className="flex items-center gap-2">
                      {errors.mentorQuestions && (
                        <AlertCircleIcon className="text-negative-01 h-5 w-5" />
                      )}
                      <span>{tGeneral('mentor')}</span>
                    </div>
                  ),
                },
              ]}
            />
            {activeTabId === Tabs.LEARNER && (
              <SurveyEditQuestions
                alwaysShowQuestionsToMentor={true}
                name="learnerQuestions"
              />
            )}
            {activeTabId === Tabs.MENTOR && (
              <SurveyEditQuestions
                alwaysShowQuestionsToMentor={true}
                name="mentorQuestions"
              />
            )}
          </ContentWrapper>
        </Form>
      </FormProvider>
    </ScrollContainer>
  );
};

export default WBAStepEdit;
