import { HeaderImage, LogoLoader, ScrollContainer } from '@stellar-lms-frontend/ui-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { QuestionFullScreen } from '../question/question-full-screen';
import { QuestionsContainer } from '../question/questions-container';
import {
  Assessment,
  Evaluation,
  LearningActivityStep,
  SubmissionResultWithScore,
  SurveyAnswerMap,
  TypeMap,
} from '@stellar-lms-frontend/lms-api';
import { getAllQuestions } from '../functions';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';

type Actions = {
  getLastAssessmentSubmission: (
    learningActivityStepId: string,
    assessmentId: string,
  ) => Promise<SubmissionResultWithScore | undefined>;
  getAssessmentById: (assessmentId: string, learningActivityId: string) => Promise<Assessment>;
  submitPartialAnswers: (
    learningActivityStepId: string,
    answers: TypeMap<SurveyAnswerMap>,
    assessmentId: string,
  ) => Promise<void>;
  evaluate: (
    learningActivityStepId: string,
    assessmentId: string,
    questionId: string,
    answer: TypeMap<SurveyAnswerMap>,
  ) => Promise<Evaluation>;
};

export type AssessmentStepViewProps = {
  step: LearningActivityStep;
  actions: Actions;
};

export const AssessmentStepView: React.FC<AssessmentStepViewProps> = ({ step, actions }) => {
  const [initialQuestionIndex, setInitialQuestionIndex] = useState<number | undefined>(undefined);
  const methods = useForm();
  const { reset } = methods;

  const [unSubmittedAssessment, setUnSubmittedAssessment] = useState<Assessment | undefined>();
  const [lastSubmission, setLastSubmission] = useState<SubmissionResultWithScore | undefined>(
    undefined,
  );
  const [evaluations, setEvaluations] = useState<Evaluation[]>();

  useEffect(() => {
    // STE-1013 Investigation
    // TODO: If we have multi-role and we know the current user's role, we can ignore fetching the last assessment if it's a designer
    const fetchAssessment = async (id: string, activityId: string) => {
      const result = await actions.getLastAssessmentSubmission(activityId, id);

      if (result) {
        setLastSubmission(result);
        setEvaluations(result.questions);
      } else {
        // if there is no submission we create a new assessment otherwise we display the results
        const data = await actions.getAssessmentById(id, activityId);
        setEvaluations(data.evaluatedQuestions);

        const questions = getAllQuestions(data.questions);
        setInitialQuestionIndex(data.questions.findIndex((q) => !q.answered));
        reset(questions);

        setUnSubmittedAssessment(data);
      }
    };

    if (step.sourceId) {
      fetchAssessment(step.sourceId, step.id);
    }
  }, [step, reset, actions]);

  const evaluateFunction = useCallback(
    async (assessmentId: string, questionId: string, answer: TypeMap<SurveyAnswerMap>) => {
      if (assessmentId) {
        const evaluation = await actions.evaluate(step.id, assessmentId, questionId, answer);
        // we need to keep track of all the evaluations that happened
        setEvaluations((prevEvaluations) => [...(prevEvaluations ?? []), evaluation]);

        return evaluation;
      }
      // FUTURE: we need to handle the case where the assessmentId is null
      //         - Create a backend function that can evaluate the question without needing a corresponding assessment id
      return null;
    },
    [actions, step.id],
  );

  const submitPartialAnswersFunction = useCallback(
    async (answers: TypeMap<SurveyAnswerMap>, assessmentId: string) => {
      if (assessmentId) {
        return await actions.submitPartialAnswers(step.id, answers, assessmentId);
      }
      return Promise.resolve();
    },
    [actions, step.id],
  );

  const questionContainerProps = useMemo(() => {
    let tempQuestionContainerProps = null;
    if (lastSubmission) {
      tempQuestionContainerProps = {
        questions: lastSubmission.questions,
        evaluations: evaluations,
      };
    } else if (unSubmittedAssessment) {
      tempQuestionContainerProps = {
        questions: unSubmittedAssessment.questions,
        evaluations: evaluations,
        submitPartialAnswersFunc: submitPartialAnswersFunction,
        evaluateQuestionFunc: evaluateFunction,
        initialQuestionIndex: initialQuestionIndex,
      };
    }
    return tempQuestionContainerProps;
  }, [
    evaluateFunction,
    evaluations,
    initialQuestionIndex,
    lastSubmission,
    submitPartialAnswersFunction,
    unSubmittedAssessment,
  ]);

  const {
    query: { data: company },
  } = useCurrentCompany(defaultGraphqlClient);

  if (!company) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      {questionContainerProps ? (
        <ScrollContainer
          scrollOnDesktop={true}
          isFullHeight={true}
        >
          <div className="flex h-full w-full flex-col">
            <HeaderImage
              companyId={company.id}
              isEditing={false}
              value={step.headerImage ?? undefined}
            />
            <div className="shrink-0 grow">
              <QuestionsContainer
                children={(props) => <QuestionFullScreen {...props} />}
                allowPrevious
                allowSkip
                {...questionContainerProps}
              />
            </div>
          </div>
        </ScrollContainer>
      ) : (
        <LogoLoader />
      )}
    </FormProvider>
  );
};

export default AssessmentStepView;
