import { Node } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    supportPopup: {
      openSupportPopup: () => ReturnType;
    };
  }
}

export const SupportPopup = (onOpenSupport: () => void) =>
  Node.create({
    name: 'supportPopup',

    addCommands() {
      return {
        openSupportPopup:
          () =>
          ({ commands }) => {
            onOpenSupport();
            return commands.focus();
          },
      };
    },
  });

export default SupportPopup;
